import styled from 'styled-components'

export const AdditionalsWrapper = styled.div`
	min-width: 100%;
	padding: 0;

	::-webkit-scrollbar {
		width: 9px;
	}
	
	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'}; 
		border-radius: 10px;
	}
	
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000'}; 
		-webkit-box-shadow: inset 0 0 6px ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'}; 
	}
`

export const AdditionalWrapper = styled.div`
  &.maximum {
    > ul {
      > li {
        &:not(.checked) {
          opacity: .5;
        }
      }
    }

		> span {
			color: #F00;
			animation: required .5s;
			position: relative;
			margin: 1rem;
			font-size: 12px;
		}
  }

  &.required {
    > div {

      > h3 {
        color: #F00;
        animation: required .5s;
        position: relative;
      }

      > span {
        color: #F00;
        border-color: #F00;
        animation: required .5s;
        position: relative;
      }
    }
  }

  @keyframes required {
    from { left: 0;}
    25% { left: 0.938rem }
    50% { left: -15px }
    75% { left: 0.938rem }
    to { left: 0; }
  }
`

export const AdditionalHeader = styled.div`
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.backgroundBody) || '#000'};
  flex: 1;
  padding: 1rem 1.5rem;
  margin: 0 -1.5rem;
  margin-bottom: 1.25rem;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
	box-sizing: border-box;

  > h3 {
    flex: 1;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
    min-height: 1.563rem;
		font-size: 1.25rem;
  }

  > span {
    border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'} 0.063rem solid;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
    font-size: 0.875rem;
    height: 1.25rem;
    min-height: 1.563rem;
    border-radius: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.625rem;
    flex: 1;
    max-width: 6.25rem;
    min-width: 6.25rem;
  }

	@media (min-width: 40rem) {
		padding: .5rem 1rem;
		margin: unset;
	}
`

export const AdditionalList = styled.ul`
  list-style: inside none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 100%;
	max-width: 100%;
  padding-bottom: 0;
	margin: 0;
  margin-bottom: 1rem;

	@media (min-width: 40rem) {
		margin-bottom: 0;
		margin-top: .5rem;
		margin-left: 1rem;
		padding-right: 1rem;
		max-width: calc(100% - 2rem);
		min-width: calc(100% - 2rem);
	}
`

export const QuantityControlWrapper = styled.div`
	align-self: flex-end;
	padding: 0;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: row;
	border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'} 0.125rem solid;
	border-radius: 1.4rem;

	> button {
		border-radius: 50%;
		flex: 1;
		color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
		font-size: 1rem;
		min-width: 1.875rem;
		min-height: 1.875rem;
		max-width: 1.875rem;
		max-height: 1.875rem;
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		> span {
		display: flex;
		justify-content: center;
		align-items: center;
		}
	}

	> span {
		flex: 1;
		margin: 0 0.625rem;
		color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
		font-size: 1.125rem;
		font-weight: bold;
    min-height: 1.875rem;
    max-height: 1.875rem;
    margin: 0 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
	}
`

export const Span = styled.span``

export const H3 = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.backgroundBody) || '#999'};
`

export const H4 = styled.h4`
  margin: 0 0 .75rem 0;
  padding: 0;
  font-size: 1.25rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};
`

export const Textarea = styled.textarea`
  min-width: 100%;
  max-width: 100%;
  height: 5rem;
  border-radius: .938rem;
  padding: .625rem;
  box-sizing: border-box;
  border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'} .063rem solid;
  background: none;
`

export const Div = styled.div``

export const Paragraph = styled.p`
	color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'} 0.063rem solid;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 1;
	min-width: 100%;
`

export const Button = styled.button`
	background: none;
	padding: 0;
	margin: 0;
`

export const AdditionalsListItemWrapper = styled.li`
	flex: 1;
	padding: 0;
	min-width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: .5rem;
	padding-right: 1rem;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
		min-width: calc(100% - 2.5rem);
		max-width: calc(100% - 2.5rem);

    &.actions {
      align-items: flex-start;
			flex: 1;
			min-width: 2rem;
      max-width: 2rem;

      > span {
        display: flex;
      }
    }

    > p {
      &.name {
        font-size: 1rem;
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
        font-weight: bold;
      }

      &.description {
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
        margin-bottom: 0.375rem;
        font-size: 0.75rem;
      }

      &.value {
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.greyMedium) || '#000'};
        font-size: 1rem;
      }
    }
  }

	&:last-child {
		margin-bottom: 0;
	}
`
