import styled from "styled-components";
import { PRODUCT_IMAGE_SCALE } from "../../../config";

export const Image = styled.img`
	border-radius: 0.625rem;
`;

export const Span = styled.span``;

export const Wrapper = styled.div`
	align-items: center;
	justify-content: flex-start;
	padding: 1rem;
	border-radius: 0.5rem;
	width: 100%;
	cursor: pointer;
	background-color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.backgroundProductCard) ||
		"#000"};
	box-shadow: 2px 2px 9px 1px rgba(0, 0, 0, 0.1);
	max-width: 15rem;
	min-width: 15rem;
	max-height: 20rem;
	min-height: 20rem;
	position: relative;
	text-align: center;

	@media (min-width: 60rem) {
		${(props) => {
		if (props.opened) {
			return `max-width: calc(50% - .85rem);
        min-width: calc(50% - .85rem);`;
		} else {
			return `max-width: calc(33% - .85rem);
        min-width: calc(33% - .85rem);`;
		}
	}}
	}

	@media (min-width: 80rem) {
		${(props) => {
		if (props.opened) {
			return `max-width: calc(33% - .85rem);
        min-width: calc(33% - .85rem);`;
		}
		if (props.categorieLength > 1) {
			return `max-width: calc((100% - 4.5rem) / 4);
    		min-width: calc((100% - 4.5rem) / 4);`;
		} else {
			return `max-width: calc(33% - .85rem);
        min-width: calc(33% - .85rem);`;
		}
	}}
	}

	&:hover {
		background-color: #f7f7f7;
	}

	* {
		box-sizing: border-box;
	}
`;

export const ProductImage = styled.div`
	min-height: 10.5rem;
	max-height: 10.5rem;
	min-width: 100%;
	max-width: 100%;
	position: relative;
	margin-bottom: .6rem;

	&.no-elevate {
		margin-bottom: 1rem;
	}

	img {
		min-height: 10.5rem;
		max-height: 10.5rem;
		${() => {
		if (PRODUCT_IMAGE_SCALE === "true") {
			return `width: 100%;
				object-fit: cover;
				transform: scale(1);
				border-radius: .5rem;`;
		} else {
			return `max-width: 100%;
				min-width: auto;`;
		}
	}}
		&.scale {
			width: 100%;
			object-fit: cover;
			transform: scale(1);
			border-radius: 0.5rem;
		}

		&.noimg {
			max-width: 50%;
			max-height: unset;
			min-height: auto;
			min-width: 50%;
			height: auto;
			box-sizing: unset;
			position: absolute;
			transform: translate(-50%, -50%);
			left: 50%;
			top: 50%;
		}
	}

	@media (min-width: 60rem) {
		min-height: 10.5rem;
		max-height: 10.5rem;
		min-width: 100%;
		max-width: 100%;
		margin-bottom: 1rem;

		&.noimg {
			img {
				min-height: 7rem;
				max-height: 7rem;
				max-width: 100%;
				min-width: auto;
				margin-top: 1rem;
			}
		}
	}
`;

export const CardProductName = styled.h3`
	margin: 0;
	font-weight: 500;
	font-size: 1rem;
	color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.textHighlightColor) ||
		"#000"};
	margin-bottom: 0.5rem;
	min-height: 1rem;
	max-height: 1rem;
	line-height: 1rem;
	min-width: 100%;
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	min-width: 100%;
	max-width: 100%;

	@media (min-width: 60rem) {
		margin-bottom: 0.25rem;
		font-size: 1.3rem;
		min-height: 1.3rem;
		max-height: 1.3rem;
		line-height: 1.3rem;
	}
`;

export const CardProductDescription = styled.p`
	margin: 0;
	font-family: Nunito;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1rem;
	color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.textPrimaryColor) ||
		"#000"};
	display: block;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	max-height: 4rem;

	@media (min-width: 60rem) {
		font-size: 0.9rem;
	}
`;

export const CardProductPriceContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: calc(100% - 2rem);
	position: absolute;
	left: 1rem;
	bottom: .3rem;

	@media (min-width: 60rem) {
		margin: auto 0 0 0;
	}
`;

export const CardProductText = styled.span`
	display: flex;
	align-items: start;
	font-size: 0.6rem;
	color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.textPrimaryColor) ||
		"#000"};
	position: absolute;
	left: 0;
	top: -0.4rem;

	@media (min-width: 60rem) {
		width: 100%;
	}
`;

export const CardProductPrice = styled.span`
	font-weight: 700;
	font-size: 1.25rem;
	display: flex;
	align-items: center;

	&.promo {
		color: #19bf2b;
		font-size: 1rem;
	}

	&.value {
		color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.textHighlightColor) ||
		"#333"};
	}

	> span {
		font-size: 0.8rem;
		margin-right: 0.4rem;
	}
`;

export const CardProductPromotionalPrice = styled.span`
	font-weight: 600;
	font-size: 0.75rem;
	color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.textThirdColor) ||
		"#CCC"};
	margin: 0 0 0 0.5rem;
	text-decoration: line-through;

	@media (max-width: 40rem) {
		margin: 0 0.5rem;
	}
`;

export const IncludeButton = styled.button`
	padding: 0.3rem 1.6rem;
	border-radius: 8px;
	font-weight: 500;
	background: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.primaryColor) ||
		"#333"};
	color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.secondaryColor) ||
		"#333"};
	border: 1px solid #d9d8d9;
	cursor: pointer;
	font-size: 1rem;
	margin: 0 0 0 .4rem;

	&.disabled {
		opacity: 0.4;
		color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.invertedPrimaryColor) ||
		"#333"};
	}

	@media (max-width: 40rem) {
		padding: 0.3rem 1.2rem;
	}
`;

export const TagWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 16%;
	max-width: 16%;
	position: absolute;
	z-index: 1;
	margin-left: -12px;
`;

export const TagLoyaltyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 16%;
	max-width: 16%;
	position: absolute;
	z-index: 1;
	margin-left: 72%;
    margin-top: -4%;
`;

export const TagOne = styled.div``;

export const TagTwo = styled.div``;

export const LabelRectangular = styled.button`
	display: inline-block;
	border-radius: 0.2rem;
	font-size: 0.8rem;
	background-color: ${(props) => props.backgroundColor || "#C2C2C2"};
	color: ${(props) => props.fontColor || "#000"};
	box-shadow: 2px 1px 6px #b9b9b9;
	box-sizing: border-box;
	padding: 0.2rem 0.3rem;
	margin-bottom: 0.5rem;
`;
export const LabelLoyaltyCircular = styled.button`
	display: flex;
	flex-direction: row;
	border-radius: 50%;
	min-width: 40px;
	min-height: 40px;
	padding: 5px;
	background-color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.backgroundProductCard) ||
		"#000"};
	color: ${(props) => props.fontColor || "#000"};
	text-align: center;
	line-height: 1;
	box-sizing: content-box;
	font-size: 0.825rem;
	margin-bottom: 0.5rem;
	align-items: center;
	font-weight:bold;

	&:hover {
		background-color: #f7f7f7;
	}
`;

export const LabelCircular = styled.button`
	display: inline-block;
	border-radius: 50%;
	min-width: 20px;
	min-height: 20px;
	padding: 5px;
	background-color: ${(props) => props.backgroundColor || "#C2C2C2"};
	color: ${(props) => props.fontColor || "#000"};
	text-align: center;
	line-height: 1;
	box-sizing: content-box;
	box-shadow: 2px 1px 6px #b9b9b9;
	font-size: 0.725rem;
	margin-bottom: 0.5rem;

	&:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		padding-top: 100%;
		height: 0;
	}

	> span {
		display: inline-block;
		vertical-align: middle;
	}
`;
