import React, {
	useState,
	useCallback,
	useEffect
} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { setNotification } from '../../../redux/actions/main'
import { GetContent, pipe } from '../../../domain/helpers'
import { addressActions } from '../../../state'
import { CustomIcon, CustomLoading } from '../../components'
import {
	Button,
	CEPForm,
	CloseButton,
	ContentWrapper,
	H3,
	Input,
	LoadingWrapper,
	PostalCodeInputContainer,
	PostalWarning,
	Span,
	Wrapper,
	Paragraph
} from './styles'
import { ALTERNATIVE_STYLE_MULTI_STORE, MULTI_STORE } from '../../../config'

const Context = React.createContext({})

export function PostalCodeInput({
	label,
	value = '',
	onChange
}) {
	return <PostalCodeInputContainer>
		<Span>{label}</Span>
		<Input
			type='text'
			maxLength={9}
			max={99999999}
			placeholder='00000-000'
			value={value}
			onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
			onChange={onChange}
		/>
	</PostalCodeInputContainer>
}

PostalCodeInput.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func
}

export function PostalCodeForm({
	postalCodeValue,
	warningPostalCode,
	errorPostalCode,
	handlePostalCodeChange,
	handlePostalCodeClick
}) {
	return <CEPForm className='postal-code-container'>
		<div className="notification">
			{warningPostalCode && warningPostalCode.type === 'warning' && <PostalWarning>O CEP digitado é inválido!</PostalWarning>}
			{errorPostalCode && errorPostalCode.type === 'error' && <PostalWarning>Tente outro endereço ou volte para o cardápio!</PostalWarning>}
		</div>
		<div className="fields">
			<PostalCodeInput label='CEP' onChange={handlePostalCodeChange} value={postalCodeValue} />
			<Button onClick={handlePostalCodeClick}>OK</Button>
		</div>
	</CEPForm>
}

PostalCodeForm.propTypes = {
	postalCodeValue: PropTypes.string,
	warningPostalCode: PropTypes.shape({
		type: PropTypes.string
	}),
	errorPostalCode: PropTypes.shape({
		type: PropTypes.string
	}),
	handlePostalCodeChange: PropTypes.func,
	handlePostalCodeClick: PropTypes.func,
}

function AddressPostalCodePage(props) {
	const {
		fetchAddressByCEP,
		setCEP,
		loading,
		setAddressPostalCodeRequest,
		setAddressRequest,
		handleCloseDialogAddress,
		title,
		closeButton = true,
		accessToken,
		setNoAddressesList,
		setNotification,
		setAddress,
		addressCep
	} = props

	const [warningPostalCode, setWarningPostalCode] = useState(null)
	const [postalCode, setPostalCode] = useState('')

	useEffect(() => {
		if (addressCep) {
			setPostalCode(addressCep)
		}
	}, [addressCep])

	const handlePostalCodeChange = useCallback((event) => {
		const {
			value
		} = event.target

		setPostalCode(value)
	}, [setPostalCode])

	async function handlePostalCodeClick() {
		setNotification({})

		if (postalCode && postalCode.length < 8) {
			setWarningPostalCode({
				type: 'warning',
				message: 'O CEP digitado é inválido! Tente outro!'
			})

			return
		}

		setCEP(postalCode)

		await fetchAddressByCEP([{
			name: 'address-by-cep',
			type: 'error',
			callback: () => {
				setAddress({})

				setNotification({
					type: 'error',
					message: 'Endereço fora da área de serviço! :('
				})

				setAddress({})
				setAddressPostalCodeRequest(false)
				setAddressRequest(true)

				console.warn('PostalCode.fetchAddressByCEP.error')
			}
		}, {
			name: 'address-by-cep',
			type: 'success',
			callback: () => {
				setAddressPostalCodeRequest(false)
				setAddressRequest(true)

				if (accessToken) {
					setNoAddressesList(true)
				} else {
					setNoAddressesList(false)
				}
			}
		}])

		setWarningPostalCode(null)
	}

	return <Wrapper id='addresses-postal-code-component'>
		{closeButton && <CloseButton className='close-button' onClick={() => {
			handleCloseDialogAddress && typeof handleCloseDialogAddress === 'function' && handleCloseDialogAddress()
		}}>
			<CustomIcon name='Times' />
		</CloseButton>}

		{(!!loading.includes('address-by-cep') || !!loading.includes('geolocation-by-address')) && <LoadingWrapper id='addresses-postal-code-loading'>
			<CustomLoading
				type={'spin'}
				id='default-loading'
				height={40}
				width={40}
			/>
		</LoadingWrapper>}

		{!loading.includes('address-by-cep') && !loading.includes('geolocation-by-address') && <ContentWrapper id='addresses-postal-code-content'>
			<H3>{ALTERNATIVE_STYLE_MULTI_STORE === "true" ? `Informe seu CEP, por favor` : title ? title : `Onde deseja receber seu pedido?`}</H3>

			{MULTI_STORE === "true" && ALTERNATIVE_STYLE_MULTI_STORE === "true" &&
				<Paragraph>Assim você terá acesso aos restaurantes e ofertas mais próximos de você!</Paragraph>}

			<PostalCodeForm
				postalCodeValue={postalCode}
				warningPostalCode={warningPostalCode}
				handlePostalCodeChange={handlePostalCodeChange}
				handlePostalCodeClick={handlePostalCodeClick}
			/>
		</ContentWrapper>}
	</Wrapper>
}

AddressPostalCodePage.propTypes = {
	fetchAddressByCEP: PropTypes.func,
	setCEP: PropTypes.func,
	setAddressPostalCodeRequest: PropTypes.func,
	setAddressRequest: PropTypes.func,
	handleCloseDialogAddress: PropTypes.func,
	setNoAddressesList: PropTypes.func,
	closeButton: PropTypes.bool,
	postalCode: PropTypes.string,
	loading: PropTypes.arrayOf(PropTypes.string),
	accessToken: PropTypes.string,
	title: PropTypes.string,
	setNotification: PropTypes.func,
	setAddress: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		accessToken: state.user.accessToken || '',
		loading: (state.loading.loading) || [],
		addressCep: state.address.cep || '',
	}
}

const GetConnection = connect(mapStateToProps, {
	setCEP: addressActions.setCEP,
	fetchAddressByCEP: addressActions.fetchAddressByCEP,
	setAddressPostalCodeRequest: addressActions.setAddressPostalCodeRequest,
	setAddressRequest: addressActions.setAddressRequest,
	setNoAddressesList: addressActions.setNoAddressesList,
	setAddress: addressActions.setAddress,
	setNotification
})

export const AddressPostalCode = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'addresses' })
)(AddressPostalCodePage))
