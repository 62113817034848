import makeFetchAddressByCEP from './app/address-by/fetchAddressByCEP'
import makeFetchAddressByGeolocation from './app/address-by/fetchAddressByGeolocation'
import makeFetchGeolocationByAddress from './app/address-by/fetchGeolocationByAddress'
import makeFetchCart from './app/cart/fetchCart'
import makeSendContactUs from './app/contact-us/sendContactUs'
import makeAddCoupon from './app/coupon/addCoupon'
import makeFetchCoupons from './app/coupon/fetchCoupons'
import makeFetchHighlights from './app/highlight/fetchHighlights'
import makeFetchMenuSearch from './app/menu/fetchSearch'
import makeAddAnalysis from './app/order/addAnalysis'
import makeAddOrder from './app/order/addOrder'
import makeFetchOrder from './app/order/fetchOrder'
import makeFetchOrderHistory from './app/order/fetchOrderHistory'
import makeFetchOrders from './app/order/fetchOrders'
import makeSignUp from './app/sign-up/signUp'
import makeSignUpFacebook from './app/sign-up/signUpFacebook'
import makeSignUpGoogle from './app/sign-up/signUpGoogle'
import makeSignUpPhone from './app/sign-up/signUpPhone'
import makeSignUpPhoneCode from './app/sign-up/signUpPhoneCode'
import makeFetchStore from './app/store/fetchStore'
import makeFetchStoreAddressValidation from './app/store/fetchStoreAddressValidation'
import makeFetchStores from './app/store/fetchStores'
import makeFetchStoreSettings from './app/store/fetchStoreSettings'
import makeAddUserAddress from './app/user-address/addUserAddress'
import makeDeleteUserAddress from './app/user-address/deleteUserAddress'
import makeFetchUserAddresses from './app/user-address/fetchUserAddresses'

import {
	API_URL,
	APP_ID,
	DESCRIPTION,
	GOOGLE_API_KEY,
	PLATAFORM_ID,
	TITLE,
	URL
} from './config'

import { mapAddressByCEPData, mapAddressByGeolocationData, mapGeolocationByAddressData } from './domain/address-by'
import { mapCartList } from './domain/cart'
import { mapContactUsData, mapContactUsRequest } from './domain/contact-us'
import { mapCouponData, mapCouponRequest, mapCouponsData } from './domain/coupon'
import { mapHighlightsList } from './domain/highlight'
import {
  mapOrderAnalysisData,
  mapOrderAnalysisRequest,
  mapOrderCreateData,
  mapOrderCreateRequest,
  mapOrderData,
  mapOrderHistoryList,
  mapOrdersList,
} from './domain/order'
import {
  mapSignUpData,
  mapSignUpFacebookData,
  mapSignUpFacebookRequest,
  mapSignUpGoogleData,
  mapSignUpGoogleRequest,
  mapSignUpPhoneCodeData,
  mapSignUpPhoneCodeRequest,
  mapSignUpPhoneData,
  mapSignUpPhoneRequest,
  mapSignUpRequest,
} from './domain/sign-up'
import {
  mapAddressValidationData,
  mapAddressValidationRequest,
  mapDeliveryStoresList,
  mapPickupStoresList,
  mapStoreData,
  mapStoresList,
  mapStoresRequest,
} from './domain/store'
import {
  mapMenuData
} from './domain/menu'
import {
  mapUserAddressCreateData,
  mapUserAddressCreateRequest,
  mapUserAddressDeleteData,
  mapUserAddressesList,
} from './domain/user-address'

import * as api from './infra/api'
import makeAddressByRepository from './infra/address-by'
import makeCartRepository from './infra/cart'
import makeContactUsRepository from './infra/contact-us'
import makeCouponRepository from './infra/coupon'
import makeHighlightRepository from './infra/highlight'
import makeMenuRepository from './infra/menu'
import makeOrderRepository from './infra/order'
import makeSignUpRepository from './infra/sign-up'
import makeStoreRepository from './infra/store'
import makeStoreSettingsRepository from './infra/store-settings'
import makeUserAddressRepository from './infra/user-address'

/* ApiService */
const apiService = api.apiService({
  localStorage: window.localStorage,
  apiUrl: API_URL,
  appId: APP_ID,
  platformId: PLATAFORM_ID
})

/* ExternalApiService */
const externalApiService = api.externalApiService()

const auth = () => api.withAuthHeader('main')

/* AddressBy */
const addressByRepository = makeAddressByRepository({
  externalApiService
})

const fetchAddressByGeolocation = makeFetchAddressByGeolocation({
  addressByRepository,
  maps: {
    mapAddressByGeolocationData
  },
  data: {
    key: GOOGLE_API_KEY
  }
})

const fetchAddressByCEP = makeFetchAddressByCEP({
  addressByRepository,
  maps: {
    mapAddressByCEPData
  },
  data: {}
})

const fetchGeolocationByAddress = makeFetchGeolocationByAddress({
  addressByRepository,
  maps: {
    mapGeolocationByAddressData
  },
  data: {
    key: GOOGLE_API_KEY
  }
})

/* Cart */
const cartRepository = makeCartRepository({
  apiService
})

const fetchCart = makeFetchCart({
  cartRepository,
  auth,
  maps: {
    mapCartList
  }
})

/* Highlight */
const highlightRepository = makeHighlightRepository({
  apiService,
})

const fetchHighlights = makeFetchHighlights({
  highlightRepository,  
  auth,
  maps: {
    mapHighlightsList
  }
})

/* Order */
const orderRepository = makeOrderRepository({
  apiService
})

const fetchOrders = makeFetchOrders({
  orderRepository,
  auth,
  maps: {
    mapOrdersList
  }
})

const fetchOrder = makeFetchOrder({
  orderRepository,
  auth,
  maps: {
    mapOrderData
  }
})

const fetchOrderHistory = makeFetchOrderHistory({
  orderRepository,
  auth,
  maps: {
    mapOrderHistoryList
  }
})

const addOrder = makeAddOrder({
  orderRepository,
  auth,
  maps: {
    mapOrderCreateRequest,
		mapOrderCreateData
  }
})

const addAnalysis = makeAddAnalysis({
  orderRepository,
  auth,
  maps: {
    mapOrderAnalysisRequest,
		mapOrderAnalysisData
  }
})

/* SignUp */
const signUpRepository = makeSignUpRepository({
  apiService
})

const signUp = makeSignUp({
  signUpRepository,
  auth,
  maps: {
    mapSignUpData,
    mapSignUpRequest
  }
})

const signUpPhone = makeSignUpPhone({
  signUpRepository,
  auth,
  maps: {
    mapSignUpPhoneData,
    mapSignUpPhoneRequest
  }
})

const signUpPhoneCode = makeSignUpPhoneCode({
  signUpRepository,
  auth,
  maps: {
    mapSignUpPhoneCodeData,
    mapSignUpPhoneCodeRequest
  }
})

const signUpFacebook = makeSignUpFacebook({
  signUpRepository,
  auth,
  maps: {
    mapSignUpFacebookData,
    mapSignUpFacebookRequest
  }
})

const signUpGoogle = makeSignUpGoogle({
  signUpRepository,
  auth,
  maps: {
    mapSignUpGoogleData,
    mapSignUpGoogleRequest
  }
})

/* Coupon */
const couponRepository = makeCouponRepository({
  apiService
})

const fetchCoupons = makeFetchCoupons({
  couponRepository,
  auth,
  maps: {
    mapCouponsData
  }
})

const addCoupon = makeAddCoupon({
  couponRepository,
  auth,
  maps: {
    mapCouponData,
    mapCouponRequest
  }
})

/* ContactUs */
const contactUsRepository = makeContactUsRepository({
  apiService
})

const sendContactUs = makeSendContactUs({
  contactUsRepository,
  auth,
  maps: {
    mapContactUsData,
    mapContactUsRequest
  }
})

/* UserAddress */
const userAddressRepository = makeUserAddressRepository({
  apiService
})

const fetchUserAddresses = makeFetchUserAddresses({
  userAddressRepository,
  auth,
  maps: {
    mapUserAddressesList
  }
})

const addUserAddress = makeAddUserAddress({
  userAddressRepository,
  auth,
  maps: {
    mapUserAddressCreateRequest,
    mapUserAddressCreateData
  }
})

const deleteUserAddress = makeDeleteUserAddress({
  userAddressRepository,
  auth,
  maps: {
    mapUserAddressDeleteData
  }
})

/* Menu */
const menuRepository = makeMenuRepository({
	apiService
})

const fetchSearch = makeFetchMenuSearch({
	menuRepository,
	auth,
	maps: {
		mapMenuData
	}
})

/* Store */
const storeRepository = makeStoreRepository({
  apiService
})

const fetchStores = makeFetchStores({
  storeRepository,
  auth,
  maps: {
    mapPickupStoresList,
    mapDeliveryStoresList,
    mapStoresList,
    mapStoresRequest
  }
})

const fetchStore = makeFetchStore({
  storeRepository,
  auth,
  maps: {
    mapStoreData
  }
})

const fetchStoreAddressValidation = makeFetchStoreAddressValidation({
  storeRepository,
  auth,
  maps: {
    mapAddressValidationData,
    mapAddressValidationRequest
  }
})

/* Store Settings */
const storeSettingsRepository = makeStoreSettingsRepository({
	URL,
	TITLE,
	DESCRIPTION
})

const fetchStoreSettings = makeFetchStoreSettings({
  storeSettingsRepository
})

export {
	addUserAddress,
	deleteUserAddress,
  addCoupon,
  addOrder,
	addAnalysis,
  fetchAddressByGeolocation,
	fetchAddressByCEP,
	fetchGeolocationByAddress,
  fetchCart,
  fetchCoupons,
  fetchHighlights,
	fetchSearch,
  fetchOrders,
  fetchOrder,
  fetchOrderHistory,
  fetchStore,
  fetchStores,
  fetchStoreAddressValidation,
  fetchUserAddresses,
	fetchStoreSettings,
  sendContactUs,
  signUp,
	signUpPhone,
	signUpPhoneCode,
  signUpFacebook,
  signUpGoogle
}
