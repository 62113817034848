import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  margin: 0 0 1.2rem;

  & > :not(:first-child) {
    margin: 0;
  }

  @media (min-width: 50rem) {
    flex-direction: column;
    margin: 2rem 0 1.2rem;

    & > :not(:first-child) {
      margin: 1.5rem 0 0 0;
    }
  }
`

export const SelectedItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ItemLabel = styled.label`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
`

export const ItemLabelSelect = styled.label`
  font-weight: 700;
  font-size: .9rem;
  line-height: 1.375rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  cursor: pointer;
`

export const DeliveryContent = styled.span`
  font-size: 1.25rem;
  line-height: 1.688rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  display: flex;
  align-items: center;
  display: inline-block;

  svg {
    width: .75rem;
    margin: 0 .5rem 0 0;
    height: auto

    path {
      fill: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    }
  }

  >span{
    &.scheduling{
      font-size: 1rem;
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
    }
  }
`

export const SelectedItemAction = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    path {
      fill: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
    }
  }

`

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;

  &:hover {
     svg path {
    fill: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
     } 
  }
`

export const PaymentMethodContent = styled.span`
  font-size: 1.25rem;
  line-height: 1.688rem;
  color: #7D7D7D;
  display: flex;
  align-items: center;
  display: flex;

  svg {
    width: 2rem;
    margin: 0 .5rem 0 0;
    height: auto;

    path {
      fill: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
    }
  }
`

export const CPFContent = styled.span`
  font-size: 1.25rem;
  line-height: 1.688rem;
  color: #7D7D7D;
  display: flex;
  align-items: center;
  display: flex;
`

export const ToggleItemAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  > .MuiSwitch-root + span {
    margin-top: 1rem;
  }

  svg {
    path {
      fill: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
    }
  }

`

export const CPFLabel = styled.span`
  font-weight: bold;
  font-size: .75rem;
  line-height: 1rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
`

export const BrandImage = styled.img`
  width: 2rem;
  margin-right: 1rem;
`

export const ItemScheduling = styled.div`
  background: #fff;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: .15rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#EEE'};

  @media (min-width: 50rem) {
    border: .15rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#EEE'};
    border-radius: 15px;
    padding: .85rem;
    height: 5rem;
    min-height: 5rem;
    max-height: 5rem;
  }
`
export const SelectedItemContentScheduling = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
`
