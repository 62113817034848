import React, {
	useState
} from 'react'
import PropTypes from 'prop-types'

export function CustomImage(props) {
	const [src, setSrc] = useState(props.src)
	const [error, setError] = useState(false)

  const onError = () => {
    if (!error) {
			setSrc(props.fallbackSrc)
			setError(true)
    }
  }

  return <img
		alt={props.alt}
		name={props.name}
		id={props.id}
		src={src}
		onError={onError}
	/>
}

CustomImage.propTypes = {
  alt: PropTypes.string,
  fallbackSrc: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  src: PropTypes.string
}
