import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as Arrow } from './../../assets/icon_fechar.svg'

import {
  Wrapper,
  CartContainerHeader,
  CartStoreDescription,
  CartStoreName,
  CartStoreIconContainer,
  CartContainerContent,
  CartContainerIconArrow
} from './styles'

export function CartContainer({ 
  logo, 
  children, 
  name, 
  storeImg, 
  cartClicked,
  openCart,
  setOpenCart,
	minimumValue,
	cartTotal
}) {
	const resize = useCallback(() => {
		const windowInnerHeight = window.innerHeight
		const headerHeight = (16 * 5.625) + (1 * 16)
		const buttonHeight = (16 * 5.625)
		const minimumValueWarningHeight = cartTotal >= minimumValue ? 0 : 60
		const wrapperHeight = windowInnerHeight - (headerHeight + buttonHeight + minimumValueWarningHeight)
		const cartListHeight = windowInnerHeight - (headerHeight + buttonHeight + minimumValueWarningHeight)
		const cartContentHeight = windowInnerHeight - (headerHeight + 16)

		const sidebarContentQuery = document.querySelectorAll('div > aside > div > div')
		const sidebarContentElement = sidebarContentQuery && sidebarContentQuery[0]
		
		if (sidebarContentElement) {
			sidebarContentElement.style.height = wrapperHeight + 'px'
			sidebarContentElement.style.minHeight = wrapperHeight + 'px'
			sidebarContentElement.style.maxHeight = wrapperHeight + 'px'
		}

		const cartListQuery = document.querySelectorAll('#cart-list')
		const cartListElement = cartListQuery && cartListQuery[0]
		
		if (cartListElement) {
			cartListElement.style.height = cartListHeight + 'px'
			cartListElement.style.minHeight = cartListHeight + 'px'
			cartListElement.style.maxHeight = cartListHeight + 'px'
		}

		const cartContentQuery = document.querySelectorAll('#cart-content')
		const cartContentElement = cartContentQuery && cartContentQuery[0]
		
		if (cartContentElement) {
			cartContentElement.style.height = cartContentHeight + 'px'
			cartContentElement.style.minHeight = cartContentHeight + 'px'
			cartContentElement.style.maxHeight = cartContentHeight + 'px'
		}
	}, [
		cartTotal,
		minimumValue
	])

	useEffect(() => {
		window.addEventListener('resize', resize)

		resize()

		return () => {
			window.removeEventListener('resize', resize)
		}
	}, [
		resize
	])

  return (
    <Wrapper>
      <CartContainerHeader>
        <CartContainerIconArrow onClick={() => {
          cartClicked()
          setOpenCart(openCart ? false : true)
        }}>
          <Arrow />
        </CartContainerIconArrow>
        <CartStoreDescription>
          Seu pedido em: <CartStoreName>{name}</CartStoreName>
        </CartStoreDescription>
        <CartStoreIconContainer>
          <img src={storeImg ? storeImg : logo} alt="logo" />
        </CartStoreIconContainer>
      </CartContainerHeader>
      <CartContainerContent>
        {children}
      </CartContainerContent>
    </Wrapper>
  )
}

CartContainer.propTypes = {
	logo: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  storeImg: PropTypes.string,
  cartClicked: PropTypes.func,
  openCart: PropTypes.bool,
  setOpenCart: PropTypes.func,
	minimumValue: PropTypes.number,
	cartTotal: PropTypes.number
}
