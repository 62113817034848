import { isDelivery, isPickup } from '../../redux/actions/main'

export default ({
  apiService
}) => {
  const strategy = {
    defaultPickup: async (payload) => {
      return await strategy['pickupRequest']({
        ...payload,
        data: {
          agglomerationId: payload.data.agglomerationId,
          cityId: payload.data.cityId,
          latitude: payload.data.latitude,
          longitude: payload.data.longitude,
          modalityId: payload.data.modalityId,
          searchText: payload.data.searchText,
          paginationOffset: payload.data.paginationOffset,
          limiteStores: payload.data.limiteStores
        }
      })
    },
    defaultDelivery: async (payload) => {
      return await strategy['deliveryRequest']({
        ...payload,
        data: {
          latitude: payload.data.latitude,
          longitude: payload.data.longitude,
          searchText: payload.data.searchText,
          paginationOffset: payload.data.paginationOffset,
          limiteStores: payload.data.limiteStores
        }
      })
    },
    pickupRequest: async ({
      data,
      auth,
      maps,
      actions
    }) => {
      const {
        latitude,
        longitude,
        limiteStores
      } = data
      const {
        setLoading
      } = actions

      const {
        mapStoresList
      } = maps

      setLoading('stores-list')

      const url = `Estabelecimentos/ObterTodos?offset=0&limit=${limiteStores}&latitude=${latitude}&longitude=${longitude}`
      const result = await apiService.get(url, auth)
      const mappedResult = !data.storeId ?
        mapStoresList({
          data: result.data,
          modalityId: 2
        }) :
        mapStoresList({
          data: result.data,
          modalityId: 2
        }).filter(filteredItem => filteredItem.id === data.storeId)

      return mappedResult && result && {
        list: mappedResult.filter(filteredItem => filteredItem && filteredItem.id
          //&& filteredItem.distance <= filteredItem.range
        ),
        remaining: result.data.Total
      }
    },
    deliveryRequest: async ({
      data,
      auth,
      maps,
      actions
    }) => {
      const {
        latitude,
        longitude,
        limiteStores
      } = data
      const {
        setLoading
      } = actions
      const {
        mapStoresList
      } = maps

      setLoading('stores-list')

      const url = `Estabelecimentos/ObterTodos?offset=0&limit=${limiteStores}&latitude=${latitude}&longitude=${longitude}`
      const result = await apiService.get(url, auth)
      const mappedResult = !data.storeId ?
        mapStoresList({
          data: result.data,
          modalityId: 4
        }) :
        mapStoresList({
          data: result.data,
          modalityId: 4
        }).filter(filteredItem => filteredItem.id === data.storeId)

      return mappedResult && result && {
        list: mappedResult.filter(filteredItem => filteredItem && filteredItem.id),
        remaining: result.data.Total
      }
    },
    addressValidationRequest: async ({
      data,
      auth,
      maps
    }) => {
      const {
        mapAddressValidationRequest,
        mapAddressValidationData
      } = maps

      const {
        storeId
      } = data

      const url = `enderecosdelivery/validar/${storeId}`
      const response = await apiService.post(url, mapAddressValidationRequest(data), auth)
      const mappedResult = mapAddressValidationData(response.data)

      return mappedResult
    },
    pickupStoreRequest: async ({
      data,
      auth,
      maps,
      getState
    }) => {
      const {
        mapStoreData
      } = maps

      const selectedPickup = getState().store && getState().store.selectedModalities && getState().store.selectedModalities.pickup
      const selectedDelivery = getState().store && getState().store.selectedModalities && getState().store.selectedModalities.delivery

      const {
        storeId,
        selectedStoreId,
        selectedCategoryIndex
      } = data

      const urlExtension = ''

      const url = `Estabelecimentos/Obter/${selectedStoreId || storeId}${urlExtension}`
      const response = await apiService.get(url, auth)
      const mappedResult = mapStoreData(response.data, selectedCategoryIndex, selectedPickup, selectedDelivery)

      return mappedResult
    },
    deliveryStoreRequest: async ({
      data,
      auth,
      maps,
      getState
    }) => {
      const {
        mapStoreData
      } = maps

      const {
        storeId,
        selectedStoreId,
        modalityId,
        selectedCategoryIndex
      } = data

      const urlExtension = modalityId === 4 ? `?delivery=true` : ''

      const selectedPickup = getState().store && getState().store.selectedModalities && getState().store.selectedModalities.pickup
      const selectedDelivery = getState().store && getState().store.selectedModalities && getState().store.selectedModalities.delivery

      const url = `Estabelecimentos/Obter/${selectedStoreId || storeId}${urlExtension}`
      const response = await apiService.get(url, auth)
      const mappedResult = mapStoreData(response.data, selectedCategoryIndex, selectedPickup, selectedDelivery)

      return mappedResult
    }
  }

  return {
    fetchStores: async (payload) => {
      const {
        dispatch
      } = payload

      const isModalityPickup = !!dispatch(isPickup())
      const isModalityDelivery = !!dispatch(isDelivery())

      if (isModalityPickup) {
        return await strategy['defaultPickup'](payload)
      }

      if (isModalityDelivery) {
        return await strategy['defaultDelivery'](payload)
      }
    },

    fetch: async (payload) => {
      const {
        data
      } = payload

      const {
        modalityId
      } = data

      const isModalityPickup = modalityId === 2
      const isModalityDelivery = modalityId === 4

      if (isModalityPickup) {
        return await strategy['pickupStoreRequest'](payload)
      }

      if (isModalityDelivery) {
        return await strategy['deliveryStoreRequest'](payload)
      }
    },

    fetchStoreAddressValidation: async (payload) => {
      return await strategy['addressValidationRequest'](payload)
    }
  }
}
