
import React from 'react'
import { maskCpf } from '../../../../domain/helpers'
import history from '../../../../history'

import { getCardBrandImageById } from '../../../../infra/utils'

import { ReactComponent as LocationIcon } from '../../../assets/icons/icon_checkout_address.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/icon_edit_fill.svg'

import { SelectedItem } from './SelectedItem'

import {
  Wrapper,
  SelectedItemContent,
  PaymentMethodContent,
  CPFContent,
  DeliveryContent,
  ItemLabel,
  SelectedItemAction,
  EditButton,
  BrandImage,
  ItemScheduling,
  SelectedItemContentScheduling,
  ItemLabelSelect
} from './styles'

export function SelectedItems(props) {
  const {
    userCard,
    address,
    cpf,
    modalityId,
    storeAddress,
    scheduling,
    setVisibleScheduling,
    userScheduling,
    URLParameters,
		above,
		changeSelectedPaymentMethod
  } = props

  const selectAddressDelivery = address.main || address.backup

  const displayAddress = modalityId === 4 ?
    (selectAddressDelivery && selectAddressDelivery.length > 30 ?
      (selectAddressDelivery.slice(0, -17) + '...') : selectAddressDelivery) + (address.number ? `, ` + address.number : '')
    : storeAddress.slice(0, -9) + '...'

  const fullDisplayAddress = modalityId === 4 ?
    selectAddressDelivery + (address.number ? `, ` + address.number : '') :
    storeAddress

  return <Wrapper>
    {scheduling && modalityId === 4 ? (!userScheduling ?
      <ItemScheduling>
        <SelectedItemContentScheduling>
          <ItemLabel>Data de  entrega</ItemLabel>
          <ItemLabelSelect onClick={() => setVisibleScheduling(true)}>Selecionar</ItemLabelSelect>
        </SelectedItemContentScheduling>
      </ItemScheduling> : <SelectedItem>
        <SelectedItemContent>
          <ItemLabel>Data de  entrega</ItemLabel>
          <DeliveryContent>
            <span className="scheduling">{`${userScheduling.week}, ${userScheduling ? `${userScheduling.date} de ${userScheduling.month}` : ''}`}</span>
          </DeliveryContent>
        </SelectedItemContent>
        <SelectedItemAction>
          <EditButton onClick={() => {
            setVisibleScheduling(true)
          }}>
            <EditIcon />
          </EditButton>
        </SelectedItemAction>
      </SelectedItem>) : null}
    <SelectedItem>
      <SelectedItemContent>
        <ItemLabel>{modalityId === 4 ? 'Local de entrega' : 'Local de retirada'}</ItemLabel>
        <DeliveryContent>
          <LocationIcon />
          <span>{fullDisplayAddress && fullDisplayAddress.length > 16 ? displayAddress : fullDisplayAddress}</span>
        </DeliveryContent>
      </SelectedItemContent>
    </SelectedItem>
    {above && <SelectedItem>
      <SelectedItemContent>
        <ItemLabel>Forma de pagamento</ItemLabel>
        <PaymentMethodContent>
          <BrandImage
            src={getCardBrandImageById(userCard.brandId)}
            alt={`Bandeira do cartao: ${getCardBrandImageById(userCard.brand)}`}
          />
          <span>{userCard.data}</span>
        </PaymentMethodContent>
      </SelectedItemContent>
      <SelectedItemAction>
        <EditButton onClick={() => {
          // history.push(`/${URLParameters}`)
					changeSelectedPaymentMethod()
        }}>
          <EditIcon />
        </EditButton>
      </SelectedItemAction>
    </SelectedItem>}
    <SelectedItem>
      <SelectedItemContent>
        <ItemLabel>CPF na nota</ItemLabel>
        {cpf ? <CPFContent>
          <span>{maskCpf(cpf)}</span>
        </CPFContent> : <CPFContent>
          <span>000.000.000-00</span>
        </CPFContent>}
      </SelectedItemContent>
    </SelectedItem>
  </Wrapper>
}
