import { useDispatch, useStore } from "react-redux";

import { setShowSignUp } from "../../redux/actions/main";
import history from "../../history";

export function usePhoneValidation() {
	const dispatch = useDispatch();
	const mainState = useStore().getState().main;
	const isPhoneInvalid =
		!mainState || !mainState.login || mainState.login.subscriptionStatus === 2;

	const showPhoneValidation = (isMobile = false) => {
		if (isMobile) {
			history.push(`/sign-up`)
		} else {
			dispatch(setShowSignUp(true));
		}
	};

	return {
		isPhoneInvalid,
		showPhoneValidation,
	};
}
