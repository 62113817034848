import React, {
	useState
} from 'react'
import { connect } from 'react-redux'
import {
	Dialog,
	Slide
} from '@material-ui/core'
import { Helmet } from 'react-helmet'

import {
	useStoreSettings
} from '../hooks'

import {
	cart,
	coupon,
	order
} from '../../state'

import history from '../../history'

import {
	pipe,
	GetContent
} from '../../domain/helpers'

import {
	setCart,
	setUserHistory,
	setNotification,
	setCVV,
	setScheduling
} from '../../redux/actions/main'

import {
	CouponsList
} from '../coupons/simple'

import {
	MainHeader
} from '../main-header'

import { Scheduling } from '../scheduling'

import {
	Wrapper,
	Main,
} from './styles'

import {
	Details,
	Summary
} from './'

export const Context = React.createContext({})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

function OrderConfirmationComponent(props) {
	const {
		userCard,
		address,
		storeName,
		storeStatus,
		loading,
		cpf,
		location,
		theme,
		addCoupon,
		setCoupon,
		setCartCoupon,
		coupon,
		cartCoupon,
		handleGTM,
		postOrder,
		setCart,
		setUserHistory,
		setNotification,
		modalityId,
		storeImg,
		storeAddress,
		scheduling,
		userScheduling,
		setScheduling,
		deliveryFee,
		products,
		URLParameters,
		couponField
	} = props

	const storeLogo = theme.logoImageStore

	const [hasCpf, setHasCpf] = useState(false)
	const [visibleCoupons, setVisibleCoupons] = useState(false)
	const [visibleScheduling, setVisibleScheduling] = useState(false)
	const [error, setError] = useState(undefined)
	const [success, setSuccess] = useState(false)
	const [above, setAbove] = useState(false)

	const {
		url,
		title,
		description
	} = useStoreSettings()

	function postCoupon() {
		addCoupon([(data) => {
			setCartCoupon({
				error: true
			})
			setError(data.message)
			setCoupon('')
			setTimeout(() => {
				setError(undefined)
			}, 5500)
		}, (data) => {
			setCartCoupon({
				code: coupon,
				...data
			})
			setSuccess(true)
			setTimeout(() => {
				setSuccess(false)
			}, 5500)
		}])
	}

	function handleCoupon(data) {
		setCartCoupon({
			...data
		})

		setVisibleCoupons(false)
	}

	function handleAddCoupon() {
		postCoupon()
	}

	function handleRemoveCoupon() {
		setCartCoupon('')
		setCoupon('')
	}

	function handleViewCoupons() {
		setVisibleCoupons(true)
	}

	function handleCloseDialogCoupons() {
		setVisibleCoupons(false)
	}

	function handleCloseDialogSecheduling() {
		setVisibleScheduling(false)
	}

	function handleHasCpf() {
		setHasCpf(!hasCpf)
	}

	async function handleFinish() {
		if (storeStatus && storeStatus !== 1) {
			setNotification({
				type: 'warning',
				message: 'Ops! A loja está fechada. Tente em outro horário!!'
			})

			return
		}

		await postOrder([{
			name: 'order',
			type: 'error',
			callback: () => {

			}
		}, {
			name: 'order',
			type: 'success',
			callback: (payload) => {
				const {
					total,
					vqpOrderId,
				} = payload

				const {
					name,
					value,
					minValue,
					typeValueModalityDelivery
				} = coupon

				const checkoutDataFinish = {
					event: 'purchase',
					ecommerce: {
						purchase: {
							actionField: {
								id: vqpOrderId,
								affiliation: 'Online Store',
								revenue: coupon && (total > minValue) && !typeValueModalityDelivery ? total - value : total,
								tax: '',
								shipping: deliveryFee,
								coupon: coupon ? name : '',
							},
							products: products ? products.map((product) => {
								return {
									name: product.name,
									id: product.id,
									value: product.value,
									brand: 'Seara',
									category: product.category.name,
									variant: '',
									quantity: product.quantity
								}
							}) : []
						}
					}
				}

				handleGTM(checkoutDataFinish)

				setCart({
					products: null
				})

				setScheduling(null)

				history.push(`/receipt${URLParameters}`)
			}
		}, {
			name: 'address',
			type: 'error',
			callback: () => {
				setUserHistory({
					next: 'cart',
					back: 'cart'
				})

				setNotification({
					type: 'warning',
					message: 'Não foi possível cadastrar seu endereço. Tente novamente.'
				})

				history.push(`/new-address${URLParameters}`)
			}
		}, {
			name: 'address',
			type: 'success',
			callback: () => {
				console.warn('Address created')
			}
		}], {
			update: false
		})
	}

	return <Wrapper>
		<Helmet>
			<title>{`${title} - Confirmação de pedido`}</title>
			<meta name='description' content={description} />
			<link rel='canonical' href={`${url}/order-confirmation`} />
		</Helmet>

		<MainHeader
			theme={theme}
			mode='simple'
			storeName={storeName}
			storeLogo={storeLogo}
		/>

		<Main>
			<Details
				loading={loading}
				userCard={userCard}
				address={address}
				coupon={cartCoupon ? cartCoupon.code : coupon}
				cartCoupon={cartCoupon}
				setCoupon={setCoupon}
				hasCpf={hasCpf}
				cpf={cpf}
				storeStatus={storeStatus}
				handleRemoveCoupon={handleRemoveCoupon}
				handleHasCpf={handleHasCpf}
				handleFinish={handleFinish}
				handleAddCoupon={handleAddCoupon}
				handleViewCoupons={handleViewCoupons}
				theme={theme}
				modalityId={modalityId}
				storeAddress={storeAddress}
				scheduling={scheduling}
				setVisibleScheduling={setVisibleScheduling}
				userScheduling={userScheduling}
				URLParameters={URLParameters}
				error={error}
				success={success}
				couponField={couponField}
				above={above}
			/>

			<Summary
				mode='simple'
				storeName={storeName}
				storeLogo={storeLogo}
				storeImg={storeImg}
				pathName={location.pathname}
				above={above}
				setAbove={setAbove}
			/>

			<Dialog
				onClose={() => handleCloseDialogCoupons()}
				aria-labelledby="coupons-list-dialog"
				open={visibleCoupons}
				TransitionComponent={Transition}
				maxWidth={'xs'}
				className="my-coupons"
				fullWidth={true}
			>
				<CouponsList
					theme={theme}
					handleCoupon={handleCoupon}
					handleCloseCoupon={setVisibleCoupons}
					modal={true}
				/>
			</Dialog>

			<Dialog
				onClose={() => handleCloseDialogSecheduling()}
				aria-labelledby="scheduling-dialog"
				open={visibleScheduling}
				TransitionComponent={Transition}
				maxWidth={'xs'}
				fullWidth={true}
			>
				<Scheduling handleCloseDialogSecheduling={handleCloseDialogSecheduling} />
			</Dialog>
		</Main>
	</Wrapper>
}

const mapStateToProps = (state) => {
	return {
		storeName: state.store.store && state.store.store.name,
		storeStatus: state.store.store && state.store.store.status,
		coupon: state.main.coupon || '',
		couponField: state.main.couponField || '',
		cartCoupon: (state.main.cart && state.main.cart.coupon) || '',
		cpf: state.user.cpf || '',
		userCard: state.main.userCard || '',
		address: state.address.address || '',
		loading: state.loading.loading || '',
		products: (state.main.cart && state.main.cart.products) || [],
		modalityId: state.main.modality && state.main.modality.id,
		storeImg: (state.store.store && state.store.store.logo) || '',
		storeAddress: state.store.store && state.store.store.address,
		scheduling: state.store.store && state.store.store.scheduling,
		userScheduling: state.main.scheduling,
		deliveryFee: state.store.store && state.store.store.deliveryFee,
		URLParameters: (state.main.URLParameters) || ''
	}
}

const GetConnection = connect(mapStateToProps, {
	addCoupon: coupon.addCoupon,
	setCoupon: coupon.setSelectedCoupon,
	postOrder: order.addOrder,
	setCartCoupon: cart.setCartCoupon,
	setCart,
	setUserHistory,
	setNotification,
	setCVV,
	setScheduling
})

export const OrderConfirmation = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'order-confirmation' })
)(OrderConfirmationComponent))
