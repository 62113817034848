import React, {
	useEffect,
	useState
} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
	user,
	addressActions,
	menuActions
} from '../../state'

import {
	pipe,
	GetContent
} from '../../domain/helpers'

import {
	CustomIcon,
	CustomImage
} from '../components'

import {
	useLayout,
	useSidebar
} from '../hooks'

import NoProduct from '../assets/no-product-card.png'

import {
	removeSelectedProduct,
	addProduct,
	removeProduct,
	handleGTM,
	//removeSelectedProductAdditional,
	setSelectedCategory,
	setOpenCart,
	setQuantity
} from '../../redux/actions/main'

import {
	Wrapper,
	ImageWrapper,
	Textarea,
	Button,
	CloseButton,
	H1,
	H4,
	Paragraph,
	Span,
	NotesWrapper,
	Div,
	DetailBox,
	AdditionalBox,
	TagWrapper,
	TagOne,
	TagTwo,
	LabelRectangular,
	LabelCircular,
	LeftWrapper,
	RightWrapper,
	TagPointsWrapper,
	CustomImg,
	TagPoints,
	LabelCircularPoints
} from './styles'
import Selo from '../assets/icone_menu_fidelidade.png'

import {
	Additionals
} from '.'

import { useDeviceLayout } from '../hooks'
import { IMAGE_ELEVATE } from '../../config'
import { createUuid } from '../../domain/helpers/Uuid'

export const Context = React.createContext({})

const Notes = ({
	status,
	quantity,
	total,
	update,
	handleQuantity,
	handleAdd,
	additionals,
	notes,
	handleNote
}) => {
	return <NotesWrapper id='product-notes'>
		{!additionals && <>
			<H4>Alguma observação?</H4>
			<Textarea
				value={notes}
				placeholder='Deseja fazer alguma observação adicional? Digite aqui'
				onChange={(e) => handleNote(e)}
			/>
		</>}
		<Div className='row'>
			<Div className='quantity'>
				<Button onClick={() => {
					handleQuantity({
						quantity: -1
					})
				}}>-</Button>
				<Span>{quantity}</Span>
				<Button onClick={() => {
					handleQuantity({
						quantity: 1
					})
				}}>+</Button>
			</Div>
			{status && status === 1 ? <Div className='add'>
				<Button onClick={() => {
					handleAdd()
				}}>
					{/* <Span>{update ? 'Atualizar' : 'Adicionar'}</Span> */}
					<Span>{'Adicionar'}</Span>
					<Span>{`R$ ${total && (Number(total).toFixed(2)).replace('.', ',')}`}</Span>
				</Button>
			</Div> : <Div className='add'>
				<Button className='disabled' onClick={() => {
					handleAdd()
				}}>
					<Span>{'Adicionar'}</Span>
					<Span>{`R$ ${total && (Number(total).toFixed(2)).replace('.', ',')}`}</Span>
				</Button>
			</Div>}
		</Div>
	</NotesWrapper>
}

Notes.propTypes = {
	status: PropTypes.number,
	quantity: PropTypes.number,
	total: PropTypes.number,
	update: PropTypes.bool,
	handleQuantity: PropTypes.func,
	handleAdd: PropTypes.func,
	additionals: PropTypes.arrayOf(PropTypes.shape({}))
}

function ProductCardPage(props) {
	const {
		storeStatus,
		selectedProduct,
		selectedCategory,
		removeSelectedProductAdditional,
		setSelectedProduct,
		addProduct,
		//removeProduct,
		cart,
		handleClose,
		setAddressPostalCodeRequest,
		setAddressRequest,
		setNoAddressesList,
		address,
		setUserCallback,
		accessToken,
		handleStatusNotification,
		modalityId,
		setQuantity,
		handleGTM,
		storeName
	} = props

	const {
		layoutModeEnum,
		handleLayoutMode
	} = useLayout()

	const {
		sidebarContentEnum,
		handleSidebarContent,
		handleSidebarOpened
	} = useSidebar()

	const [loaded, setLoaded] = useState(false)
	const [lastQuantity, setLastQuantity] = useState(0)
	const [update, setUpdate] = useState(0)
	const [total, setTotal] = useState(0)
	const [value, setPrice] = useState(0)
	const [fromValue, setFromPrice] = useState(0)
	const [notes, setNotes] = useState("")
	const [cartProductPreviousQuantity, setCartProductPreviousQuantity] = useState(0)
	const hasAdditionals = selectedProduct && selectedProduct.additionals && selectedProduct.additionals.length
	const hasImage = selectedProduct && selectedProduct.image

	const isMobile = useDeviceLayout({
		isMobile: true
	})

	useEffect(() => {
		if (selectedProduct) {
			const {
				products
			} = cart

			const cartProductPrevious = (products && products.length) ? (products.filter(filteredItem => filteredItem.id === selectedProduct.id))[0] : null
			const cartProductPreviousQuantity = selectedProduct.isNewAddition ? 1 : ((cartProductPrevious && cartProductPrevious.quantity) || 0)
			const finalQuantity = !update && cartProductPreviousQuantity ? cartProductPreviousQuantity : selectedProduct.quantity
			const value = `R$ ${Number(selectedProduct.value).toFixed(2).replace('.', ',')}`
			const fromValue = `R$ ${Number(selectedProduct.fromValue).toFixed(2).replace('.', ',')}`

			const selectedAdditionals = (selectedProduct && selectedProduct.selectedAdditionals) || (cartProductPrevious && cartProductPrevious.selectedAdditionals)

			const selectedAdditionalsGroups = selectedAdditionals && selectedAdditionals.length && selectedAdditionals.map((mappedItem) => {
				const selectedAdditionalsMaximum = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 3)
				const selectedAdditionalsAverage = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 2)
				const selectedAdditionalsFixes = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 1)

				const additionalsMaximumTotal = (selectedAdditionalsMaximum && selectedAdditionalsMaximum.length && Math.max.apply(Math, selectedAdditionalsMaximum.map((selectedAdditionalsMaximumItem) => selectedAdditionalsMaximumItem.value))) || 0
				const additionalsAverageTotal = selectedAdditionalsAverage && selectedAdditionalsAverage.length ? (selectedAdditionalsAverage.reduce((acc, current) => Number(acc + current.value), [])) / selectedAdditionalsAverage.length : 0
				const additionalsFixesTotal = selectedAdditionalsFixes && selectedAdditionalsFixes.length ? selectedAdditionalsFixes.reduce((acc, current) => {
					if (current.quantity > 1) {
						return Number(acc + (current.value * current.quantity))
					}

					return Number(acc + current.value)
				}, []) : 0

				return additionalsMaximumTotal + additionalsAverageTotal + additionalsFixesTotal
			})
			const finalPriceProduct = !selectedProduct.promotionalValue ? selectedProduct.value : selectedProduct.promotionalValue

			const selectedAdditionalsGroupsTotal = (selectedAdditionalsGroups && selectedAdditionalsGroups.length && selectedAdditionalsGroups.reduce((acc, current) => Number(acc + current), [])) || 0

			setLastQuantity(finalQuantity)
			setTotal(finalQuantity * (finalPriceProduct + selectedAdditionalsGroupsTotal))
			setPrice(value)
			setCartProductPreviousQuantity(cartProductPreviousQuantity)
			setFromPrice(fromValue)
		}

		return () => { }
	}, [
		cart,
		selectedProduct,
		update,
		loaded,
		setLoaded
	])

	function handleQuantity(item) {
		const {
			quantity: quantityIncrement
		} = item

		const newProduct = {
			...selectedProduct,
			quantity: lastQuantity + quantityIncrement,
			category: selectedCategory,
		}

		setUpdate(true)

		if (newProduct.quantity > 999) return false
		if (newProduct.quantity < 1) return false

		setLastQuantity(newProduct.quantity)
		setSelectedProduct(newProduct)
	}

	function handleAdditionalsChange(item, hasAdded, checkbox, selectedGroupItemsQuantity) {
		const {
			quantity: quantityIncrement
		} = item

		if (hasAdded) {
			removeSelectedProductAdditional(item)

			return
		}

		const {
			groupId,
			calcType,
			minimum,
			maximum
		} = item

		const {
			products
		} = cart

		const cartProductPrevious = (products && products.length) ? (products.filter(filteredItem => filteredItem.id === item.id))[0] : null
		const selectedAdditionals = (selectedProduct && selectedProduct.selectedAdditionals) || cartProductPrevious.selectedAdditionals
		const selectedAdditionalGroup = (selectedAdditionals && selectedAdditionals.length && (selectedAdditionals.filter(filteredItem => filteredItem.groupId === groupId && filteredItem.calcType === calcType)[0])) || null

		if (selectedAdditionalGroup && selectedAdditionalGroup.items && selectedAdditionalGroup.items.length + quantityIncrement > maximum) {
			const group = document.getElementById(`additionals-group-${selectedAdditionalGroup.groupId}-${selectedAdditionalGroup.calcType}`)

			group.classList.add('maximum')

			return false
		}

		if (checkbox && selectedGroupItemsQuantity === maximum) {
			const group = document.getElementById(`additionals-group-${selectedAdditionalGroup.groupId}-${selectedAdditionalGroup.calcType}`)

			group.classList.add('maximum')

			return false
		}

		if (!selectedAdditionalGroup) {
			const newGroup = {
				groupId,
				calcType,
				minimum,
				maximum,
				items: [
					item
				]
			}

			const newProduct = {
				...selectedProduct,
				selectedAdditionals: [
					...selectedAdditionals,
					newGroup
				]
			}

			setSelectedProduct(newProduct)

			return
		}

		if (selectedAdditionalGroup) {
			const {
				items = []
			} = selectedAdditionalGroup

			const newSelectedAdditionals = selectedAdditionals.map(mappedItem => {
				if (mappedItem.groupId === groupId && mappedItem.calcType === calcType) {
					const newItems = [
						...items,
						item
					]

					const newMappedItem = {
						...mappedItem,
						items: newItems
					}

					return newMappedItem
				}

				return mappedItem
			})

			const newProduct = {
				...selectedProduct,
				selectedAdditionals: [
					...newSelectedAdditionals
				]
			}

			setSelectedProduct(newProduct)

			return
		}
	}

	function handleAdditionalsQuantity(item) {
		const {
			id,
			quantity: quantityIncrement,
			groupId,
			calcType,
			minimum,
			maximum
		} = item

		const {
			products
		} = cart

		const cartProductPrevious = (products && products.length) ? (products.filter(filteredItem => filteredItem.id === item.id))[0] : null
		const selectedAdditionals = selectedProduct.selectedAdditionals || cartProductPrevious.selectedAdditionals
		const selectedAdditionalGroup = (selectedAdditionals && selectedAdditionals.length && (selectedAdditionals.filter(filteredItem => filteredItem.groupId === groupId && filteredItem.calcType === calcType)[0])) || null

		if (selectedAdditionalGroup && selectedAdditionalGroup.items && selectedAdditionalGroup.items.length + quantityIncrement > maximum) {
			return false
		}

		if (!selectedAdditionalGroup) {
			const newItem = {
				...item,
				quantity: 1
			}

			const newGroup = {
				groupId,
				calcType,
				minimum,
				maximum,
				items: [
					newItem
				]
			}

			const newProduct = {
				...selectedProduct,
				selectedAdditionals: [
					...selectedAdditionals,
					newGroup
				]
			}

			setSelectedProduct(newProduct)

			return
		}

		if (selectedAdditionalGroup) {
			const {
				items = []
			} = selectedAdditionalGroup

			const newSelectedAdditionals = selectedAdditionals.map(mappedItem => {
				const hasSelectedAdditional = mappedItem.items && mappedItem.items.filter(filteredItem => filteredItem.code === id)

				const quantityTotal = items.reduce((acc, current) => {
					return Number(acc + current.quantity)
				}, 0)
				const quantityRemaining = maximum - quantityTotal

				if (!hasSelectedAdditional.length && mappedItem.groupId === groupId && mappedItem.calcType === calcType && quantityRemaining) {
					const newItem = {
						...item,
						quantity: 1
					}

					const newItems = [
						...items,
						newItem
					]

					const newMappedItem = {
						...mappedItem,
						items: newItems
					}

					return newMappedItem
				}

				if (hasSelectedAdditional.length && mappedItem.groupId === groupId && mappedItem.calcType === calcType && ((quantityRemaining && quantityIncrement > 0) || ((!quantityRemaining || quantityRemaining) && quantityIncrement < 0))) {
					const newItems = items && items.length && (items.map((newItemsMappedItem) => {
						const {
							quantity: newItemsMappedItemQuantity = 0
						} = newItemsMappedItem

						const newQuantity = newItemsMappedItemQuantity + quantityIncrement

						if (newQuantity >= 1 && newQuantity <= maximum && hasSelectedAdditional && hasSelectedAdditional[0] && hasSelectedAdditional[0].code === newItemsMappedItem.code) {
							return {
								...newItemsMappedItem,
								quantity: newQuantity
							}
						}

						if (newQuantity < 1 && hasSelectedAdditional && hasSelectedAdditional[0] && hasSelectedAdditional[0].code === newItemsMappedItem.code) {
							return false
						}

						return {
							...newItemsMappedItem
						}
					})).filter(filteredItem => filteredItem !== false)

					const newMappedItem = {
						...mappedItem,
						items: newItems
					}

					return newMappedItem
				}

				return mappedItem
			})

			const newProduct = {
				...selectedProduct,
				selectedAdditionals: [
					...newSelectedAdditionals
				]
			}

			setSelectedProduct(newProduct)

			return
		}
	}

	async function handleAdd() {
		const product = {
			...selectedProduct,
			uuid: createUuid(),
			notes: notes,
			quantity: lastQuantity
		}

		const {
			products
		} = cart

		const {
			additionals,
			selectedAdditionals
		} = selectedProduct

		const cartProductPrevious = (products && products.length) ? (products.filter(filteredItem => filteredItem.id === product.id))[0] : null

		const requiredAdditionals = additionals && additionals.filter(filteredItem => filteredItem.minimum >= 1)

		const getSelectedAdditionals = (requiredAdditionalsFilteredItem) => {
			return selectedAdditionals.filter(selectedAdditionalsFilteredItem => {
					const isGroupEquals = selectedAdditionalsFilteredItem.groupId === requiredAdditionalsFilteredItem.groupId &&
						selectedAdditionalsFilteredItem.calcType === requiredAdditionalsFilteredItem.calcType;

					if (!isGroupEquals) {
						return false;
					}

					const items = selectedAdditionalsFilteredItem.items || [];

					if (requiredAdditionalsFilteredItem.checkbox) {
						return items.length >= requiredAdditionalsFilteredItem.minimum;
					}

					const total = items.reduce((total, item) => total + item.quantity, 0);
					return total >= requiredAdditionalsFilteredItem.minimum;
				}
			);
		}

		const hasMinimum = requiredAdditionals && requiredAdditionals.filter(requiredAdditionalsFilteredItem => {
			const selectedAdditional = getSelectedAdditionals(requiredAdditionalsFilteredItem);

			if (!selectedAdditional.length) {
				return false
			}

			const {
				items = []
			} = selectedAdditional[0]

			if (!items.length) {
				return false
			}

			return true
		})

		const hasNoMinimum = requiredAdditionals && requiredAdditionals.filter(requiredAdditionalsFilteredItem => {
			const selectedAdditional = getSelectedAdditionals(requiredAdditionalsFilteredItem);

			if (!selectedAdditional.length) {
				return true
			}

			const {
				items = []
			} = selectedAdditional[0]

			if (!items.length) {
				return true
			}

			return false
		})

		hasMinimum && hasMinimum.length && hasMinimum.forEach((hasMinimum) => {
			const group = document.getElementById(`additionals-group-${hasMinimum.groupId}-${hasMinimum.calcType}`)

			group.classList.remove('required')
		})

		if (hasMinimum.length < requiredAdditionals.length && hasNoMinimum.length) {
			const additionals = document.getElementById(`additionals`)
			const additionalsDialog = document.querySelectorAll(`#product-view-dialog.product-detail.has-additionals.mobile .MuiDialog-paper`)[0]
			const additionalsDialogItem = additionalsDialog && additionalsDialog[0]

			additionals && hasNoMinimum.forEach((hasNoMinimumItem) => {
				const group = document.getElementById(`additionals-group-${hasNoMinimumItem.groupId}-${hasNoMinimumItem.calcType}`)
				const groupOffsetTop = group.offsetTop

				if (additionalsDialogItem) {
					additionalsDialog.scrollTop = groupOffsetTop
				} else {
					additionals.scrollTop = groupOffsetTop
				}

				group.classList.add('required')
			})

			return false
		}

		if (storeStatus !== 1) {
			handleStatusNotification()

			return
		}

		if (modalityId === 4) {
			if (address && (!address.main || !address.verified)) {
				const {
					id,
					name,
					description,
					image,
					value,
					quantity,
					additionals,
					selectedAdditionals,
					category,
					promotionalValue
				} = selectedProduct

				if (accessToken) {
					setAddressRequest(true)
					setAddressPostalCodeRequest(false)
					setNoAddressesList(false)
				} else {
					setAddressPostalCodeRequest(true)
					setAddressRequest(false)
				}

				setUserCallback({
					name: 'add-product',
					data: {
						index: 0,
						id,
						uuid: createUuid(),
						name,
						description,
						image,
						value,
						quantity,
						additionals,
						selectedAdditionals,
						category,
						promotionalValue
					}
				})

				return
			}
		}

		let verified = true;

		if (!product.isNewAddition) {
			verified = await checkDifference(product)
		}

		if (cartProductPreviousQuantity && !verified) {
			setQuantity({
				id: product.id,
				uuid: cartProductPrevious.uuid,
				quantity: 1
			})

			handleLayoutMode(layoutModeEnum.sideFullContent)
			handleSidebarContent(sidebarContentEnum.cart)
			handleSidebarOpened(true)

			handleClose()

			return
		} else {
			await addProduct(product)
			handleLayoutMode(layoutModeEnum.sideFullContent)
			handleSidebarContent(sidebarContentEnum.cart)
			handleSidebarOpened(true)

			handleClose()
		}

		//GTM 3.0 - Evento de Adicionar produto ao carrrinho
		const dataAddProduct = {
			'event': `add_to_cart`,
			'store': storeName,
			'ecommerce': {
				'currency': 'BRL',
				'items': [{
					'item_name': product.name,
					'item_id': product.id,
					'price': product.promotionalValue ? product.promotionalValue : product.value,
					'discount': null,
					'categoria': product.categoryName ?? null,
					'quantity': 1
				}]
			}
		}
		handleGTM(dataAddProduct)
		//console.warn({dataAddProduct})

	}

	const checkDifference = (product) => {
		const {
			products
		} = cart

		const cartProductPrevious = (products && products.length) ? (products.filter(filteredItem => filteredItem.id === product.id))[0] : null

		let difference = false
		const checkAddionals = []
		product && product.selectedAdditionals.forEach((additional) => {
			const selectedProductGroupId = (cartProductPrevious && cartProductPrevious.selectedAdditionals.find((selected) => selected.groupId === additional.groupId)) || null
			if (selectedProductGroupId) {
				if (additional.items && additional.items.length > 0) {
					additional.items.forEach((mappedItem) => {
						const objCheck = selectedProductGroupId.items.find((selected) => selected.code === mappedItem.code) || null

						if (objCheck) checkAddionals.push("found")
						else checkAddionals.push("not_found")
					})

					const verified = (checkAddionals.indexOf("not_found") > -1) ? true : false
					difference = verified
					return difference
				}
			} else {
				difference = true
			}
		})

		return difference
	}

	const handleNote = (e) => {
		setNotes(e.target.value)
	}

	if (selectedProduct && (!selectedProduct.name /* || !selectedProduct.value */)) {
		return null
	}

	return <>
		<Wrapper id='store-product' mobile={isMobile} className={`${hasAdditionals ? 'has-additionals' : ''} ${hasImage ? '' : 'noimg'}`}>
			<CloseButton className='close-button' onClick={() => {
				handleClose()
			}}>
				<CustomIcon name='Times' />
			</CloseButton>

			<LeftWrapper className='left'>
				{selectedProduct.tags && selectedProduct.tags.length > 0 ? <TagWrapper itens={selectedProduct.tags}>
					<TagOne>
						{selectedProduct.tags && selectedProduct.tags.length > 0 ? selectedProduct.tags.map((item, index) => {
							const key = `product-view-tag-item-${index}-${item.id}`

							if (item.type === 1) {
								if (item.format === 2) return (<LabelRectangular key={key} backgroundColor={item.backgroundColor} fontColor={item.fontColor}>{item.text}</LabelRectangular>)
								return (<LabelCircular key={key} backgroundColor={item.backgroundColor} fontColor={item.fontColor}>{item.text}</LabelCircular>)
							}
							return null
						}) : null}
					</TagOne>
					<TagTwo>
						{selectedProduct.tags && selectedProduct.tags.length > 0 ? selectedProduct.tags.map((item) => {
							if (item.type === 2) {
								if (item.format === 2) return (<LabelRectangular backgroundColor={item.backgroundColor} fontColor={item.fontColor}>{item.text}</LabelRectangular>)
								return (<LabelCircular backgroundColor={item.backgroundColor} fontColor={item.fontColor}>{item.text}</LabelCircular>)
							}
							return null
						}) : null}
					</TagTwo>
				</TagWrapper> : null}
				{selectedProduct && <DetailBox additionals={selectedProduct.additionals} mobile={isMobile}>
					{selectedProduct.image ? (IMAGE_ELEVATE === 'true' ? <ImageWrapper className='main' additionals={selectedProduct.additionals} mobile={isMobile} image={selectedProduct.image}>
						<CustomImage className='main' src={selectedProduct.image} alt={selectedProduct.name} fallbackSrc={NoProduct} />
					</ImageWrapper> : <ImageWrapper>
						<CustomImage src={selectedProduct.image} alt={selectedProduct.name} fallbackSrc={NoProduct} />
					</ImageWrapper>) : <ImageWrapper className='noimg' additionals={selectedProduct.additionals} mobile={isMobile} image={selectedProduct.image}>
						<CustomImage src={NoProduct} alt={selectedProduct.name} fallbackSrc={NoProduct} />
					</ImageWrapper>}

					{selectedProduct.loyaltyPoints > 0 && <TagPointsWrapper additionals={selectedProduct.additionals}>
						<TagPoints>
							<LabelCircularPoints backgroundColor={"#e9e9e9"} fontColor={"#949494"} key={`${selectedProduct.id}-selo`}>
								<CustomImg className={'selo'} src={Selo} width="30px" alt="Selo de pontos" /><span>x{selectedProduct.loyaltyPoints}</span>
							</LabelCircularPoints>
						</TagPoints>
					</TagPointsWrapper>}
					<H1 additionals={selectedProduct.additionals} mobile={isMobile} image={selectedProduct.image}>{selectedProduct.name}</H1>

					<Paragraph className='description'>{selectedProduct.description}</Paragraph>

					{selectedProduct.showFromValueText && <Paragraph className='from'>A partir de:</Paragraph>}

					{!selectedProduct.promotionalValue && <Paragraph className='value'>{selectedProduct.fromValue ? fromValue : value}</Paragraph>}

					{selectedProduct.promotionalValue && <Paragraph className='promo'>R$ {selectedProduct.promotionalValue ? Number(selectedProduct.promotionalValue).toFixed(2).replace('.', ',') : ''} <Span>{value}</Span></Paragraph>}
				</DetailBox>}
			</LeftWrapper>

			<RightWrapper className='right'>
				{selectedProduct && selectedProduct.additionals && selectedProduct.additionals.length ? <AdditionalBox mobile={isMobile} id="additionals">
					<Additionals
						data={selectedProduct.additionals}
						cart={cart}
						selectedProduct={selectedProduct}
						handleQuantity={handleAdditionalsQuantity}
						handleChange={handleAdditionalsChange}
					/>
				</AdditionalBox> : null}

				<Notes
					total={total}
					update={!!cartProductPreviousQuantity}
					quantity={lastQuantity}
					handleQuantity={handleQuantity}
					handleAdd={handleAdd}
					status={storeStatus}
					notes={notes}
					handleNote={handleNote}
				/>
			</RightWrapper>
		</Wrapper>
	</>
}

ProductCardPage.propTypes = {
	storeStatus: PropTypes.number,
	selectedProduct: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		description: PropTypes.string,
		image: PropTypes.string,
		value: PropTypes.number,
		quantity: PropTypes.number,
		additionals: PropTypes.arrayOf(PropTypes.shape({})),
		selectedAdditionals: PropTypes.arrayOf(PropTypes.shape({})),
		category: PropTypes.shape({}),
		promotionalValue: PropTypes.number,
		tags: PropTypes.arrayOf(PropTypes.shape({}))
	}),
	selectedCategory: PropTypes.shape({}),
	removeSelectedProductAdditional: PropTypes.func,
	setSelectedProduct: PropTypes.func,
	addProduct: PropTypes.func,
	removeProduct: PropTypes.func,
	cart: PropTypes.shape({
		products: PropTypes.arrayOf(PropTypes.shape({}))
	}),
	handleClose: PropTypes.func,
	setOpenCart: PropTypes.func,
	setAddressPostalCodeRequest: PropTypes.func,
	address: PropTypes.shape({
		main: PropTypes.string,
		verified: PropTypes.bool
	}),
	setUserCallback: PropTypes.func,
	setAddressRequest: PropTypes.func,
	setNoAddressesList: PropTypes.func,
	accessToken: PropTypes.string
}

const mapStateToProps = (state) => {
	return {
		selectedProduct: state.menu.selectedProduct || {},
		storeStatus: state.store.store && state.store.store.status,
		storeName: state.store.store && state.store.store.name,
		selectedCategory: state.store.store && state.store.store.selectedCategory,
		openModalCategory: state.store.store && state.store.store.selectedCategory && state.store.store.selectedCategory.openModalCategory,
		cart: state.main.cart,
		modalityId: state.main.modality.id,
		address: (state.address.address) || {},
		accessToken: (state.user.accessToken) || ''
	}
}

const GetConnection = connect(mapStateToProps, {
	setUserCallback: user.setUserCallback,
	setSelectedProduct: menuActions.setSelectedProduct,
	setAddressPostalCodeRequest: addressActions.setAddressPostalCodeRequest,
	setAddressRequest: addressActions.setAddressRequest,
	setNoAddressesList: addressActions.setNoAddressesList,
	removeSelectedProduct,
	removeSelectedProductAdditional: menuActions.removeSelectedProductAdditional,
	addProduct,
	removeProduct,
	handleGTM,
	setSelectedCategory,
	setOpenCart,
	setQuantity
})

export const ProductCard = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'payment' })
)(ProductCardPage))
