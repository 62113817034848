import React from 'react'
import { connect } from 'react-redux'

import {
  pipe
} from '../../../domain/helpers'

import {
  CustomIcon
} from '../../components'

import {
  setQuantity
} from '../../../redux/actions/main'

import {
  Image,
  H2,
  Wrapper,
  Div,
  Paragraph,
  Button,
  Span
} from './styles'
import { addressActions, user } from '../../../state'

export function HorizontalProductComponent(props) {
  const {
    index,
    description,
    rawPrice,
    additionals,
    selectedAdditionals,
    category,
    handleView,
    handleAdd,
    setQuantity,
    id,
    name,
    storeLogo,
    value,
    image,
    measure = '1un',
    quantity,
    promotionalValue,
    status,
    className,
    storeImg,
    hasRequiredAdditionals,
    setUserCallback,
    modalityId,
    accessToken,
    address,
    setAddressRequest,
    setAddressPostalCodeRequest,
    setNoAddressesList
  } = props

  function handleQtd(args) {
    setQuantity(args)
  }

  return <Wrapper className={`product ${className} ${!id ? 'hidden' : ''}`}>
    <Div className='holder'>
      { image && <Div className='image' onClick={ handleView }>
        <Image src={ image } />
      </Div> }

      { !image && <Div className='image-store' onClick={ handleView }>
        <Image src={ storeImg } />
      </Div> }

      { !image && storeLogo && <Div className='image store-logo' onClick={ handleView }>
        <Image src={ storeLogo } />
      </Div> }

      <H2 onClick={ handleView }>{ name }</H2>

      <Paragraph onClick={ handleView } className='measure'>{ measure }</Paragraph>

      {!promotionalValue && <Paragraph className='value'>{ value }</Paragraph>}

      {promotionalValue && <Paragraph className='promo'>{ `R$ ${ Number(promotionalValue).toFixed(2).replace('.', ',') }` } <Span>{ value }</Span></Paragraph>}

      {status && status === 1 ? (!quantity ?
        <Button onClick={(event) => {
          if (hasRequiredAdditionals) {
						handleView(event)

						return
					}

					if (modalityId === 4) {
						if (address && address.main && address.verified) {
							handleAdd(event)
						} else {
							if (accessToken) {
								setAddressRequest(true)
								setAddressPostalCodeRequest(false)
								setNoAddressesList(false)
							} else {
								setAddressPostalCodeRequest(true)
								setAddressRequest(false)
							}

							setUserCallback({
								name: 'add-product',
								data: {
									index,
									id,
									name,
									description,
									image,
									value: rawPrice,
									quantity: 1,
									additionals,
									selectedAdditionals,
									category,
									promotionalValue
								}
							})
						}
					} else {
						handleAdd(event)
					}
        }}><CustomIcon name='Plus' /></Button> :
        <Div className='quantity'>
          <Button className='active' onClick={ () => {
            if (quantity === 1) {
              handleQtd({
                id,
                quantity: 0
              })				
      
              return
            }
      
            handleQtd({
              id,
              quantity: -1
            })
          } }><CustomIcon name='Minus' /></Button>

          <Span>{ quantity }</Span>

          <Button className='active' onClick={ () => {
            if (quantity === 999) {
              return
            }
            console.log('disparando')
      
            handleQtd({
              id,
              quantity: 1
            })
          } }><CustomIcon name='Plus' /></Button>
        </Div>) : 
        <Button className='disabled'><CustomIcon name='Plus' /></Button>  
      }
      </Div>
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
		address: state.address.address && state.address.address,
		accessToken: state.user.accessToken || '',
		modalityId: state.main.modality && state.main.modality.id, 
    selectedCategory: state.store.store && state.store.store.selectedCategory
  }
}

const GetConnection = connect(mapStateToProps, {
  setUserCallback: user.setUserCallback,
  setAddressRequest: addressActions.setAddressRequest,
  setAddressPostalCodeRequest: addressActions.setAddressPostalCodeRequest,
	setNoAddressesList: addressActions.setNoAddressesList,
  setQuantity
})

export function HorizontalProduct(props) {
  const MemoizedComponent = React.memo(HorizontalProductComponent)
  const Component = pipe(
    GetConnection
  )(MemoizedComponent)

  return <Component { ...props } />
}
