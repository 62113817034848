import React, {
  useEffect
} from 'react'
import Selo from '../../assets/icone_menu_fidelidade.png'

import {
  Div,
  Paragraph,
  Wrapper
} from './styles'

const Text = ({
  points
}) => {
  return <>
    <Div>
      <img src={Selo} width="40px" alt="Sele de pontos" />
      <Paragraph>{`Você irá ganhar ${points} selos nessa compra!`}<br />{`Acumule selos e troque por prêmios em nosso App.`}</Paragraph>
    </Div>
  </>
}

export function LoyaltyPoints(props) {
  const {
    modalityId,
    points
  } = props

  const textProps = {
    points,
    modalityId
  }

  useEffect(() => {
    return () => { }
  }, [])

  return <Wrapper>
    <Text {...textProps} />
  </Wrapper>
}
