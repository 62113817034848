import React, {
  useEffect
} from 'react'

import {
  Paragraph,
  Strong,
  Wrapper
} from './styles'

const Text = ({
  modalityId,
  minimumValue
}) => {
  if (modalityId === 2 && minimumValue) {
    return <Paragraph>{`O pedido mínimo para esta loja é de `}<Strong>{`R$ ${minimumValue.toFixed(2).replace('.',',')}`}</Strong>{ `, não inclusas as taxas.`}</Paragraph>
  }

  if (modalityId === 4 && minimumValue) {
    return <Paragraph>{`O pedido mínimo para esta loja é de `}<Strong>{`R$ ${minimumValue.toFixed(2).replace('.',',')}`}</Strong>{ `, não inclusa a taxa de entrega.`}</Paragraph>
  }
  
  return null
}

export function MinimumValue(props) {
  const {
    minimumValue,
    modalityId,
    cartTotal
  } = props
  
  const textProps = {
    minimumValue,
    modalityId
  }

  useEffect(() => {
    return () => {}
  }, [])

  return cartTotal >= minimumValue ?
    null :
    <Wrapper>
      <Text {...textProps} />
    </Wrapper>
}
