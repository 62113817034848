import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '../../components/common'
import { CustomIcon } from '../../components'

import { ReactComponent as IconDelivery } from '../../assets/icon_delivery.svg'
import { ReactComponent as IconRetirada } from '../../assets/icon_retirada.svg'

import {
  Wrapper,
  Div,
  Image,
  Paragraph,
  Title,
  Span
} from './styles'

export function Store(props) {
  const {
    handles,
    data
  } = props

  const {
    id,
    coupon = {},
    name,
    distance,
    category,
    pickup,
    delivery,
		selectedStoreId,
		logo
  } = data

  const { handleStore } = handles

  const {
    value: couponValue,
    typeValueModalityDelivery
  } = coupon

  return <Wrapper className={id === selectedStoreId ? 'active' : ''} onClick={
    () => {
      handleStore({
        id,
        coupon,
        name,
				modality: delivery ? 4 : 2,
        modalities: {
          delivery,
          pickup
        }
      })
    }
  }>
    {logo && <Div className='image'>
      <Image src={logo} />
    </Div>}
    <Div className={`${logo ? 'text' : 'full-text'}`}>
      <Title>{name}</Title>

      <Paragraph className='description'>
        <Span className='distance'>{`${distance} km`} </Span> <Span className='dot'>∙</Span> <Span>{`${category}`}</Span>
      </Paragraph>

      <Div>
        <Paragraph className='service-info'>
          {delivery && <Span className='value-from'>
            <IconDelivery /> Delivery
          </Span>}
          {pickup && <Span className='time-from'>
            <IconRetirada /> Para levar
          </Span>}
        </Paragraph>
      </Div>

      {coupon && couponValue && !typeValueModalityDelivery && <Paragraph className={`coupon`}>
        <Icon name='coupon' width={'40px'} height={'40px'} /> Voucher de {`R$ ${Number(couponValue)}`} disponível
      </Paragraph>}

      {coupon && typeValueModalityDelivery && <Paragraph className={`coupon-free`}>
        <CustomIcon name='Gift' /> Compre e Ganhe
      </Paragraph>}
    </Div>
  </Wrapper>
}

Store.propTypes = {
  handles: PropTypes.shape({
		handleStore: PropTypes.func
	}),
	selectedStoreId: PropTypes.number,
  data: PropTypes.shape({
		id: PropTypes.number,
    coupon: PropTypes.shape({}),
    name: PropTypes.string,
    logo: PropTypes.string,
    distance: PropTypes.string,
		selectedStoreId: PropTypes.number,
    category: PropTypes.string,
    pickup: PropTypes.bool,
    delivery: PropTypes.bool,
    store: PropTypes.shape({
			id: PropTypes.number
		}),
	})
}
