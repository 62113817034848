import React from 'react'

import { ReactComponent as ClockIcon } from '../../../assets/icons/icon_clock.svg'
import { ReactComponent as LocationIcon } from '../../../assets/icons/icon_checkout_address.svg'

import {
  getCardBrandImageById,
  formatCpf
} from '../../../../infra/utils'
import Selo from '../../../assets/icone_menu_fidelidade.png'

import {
  DetailsContainer,
  DeliveryEstimate,
  DetailsItem,
  ItemContent,
  ItemLabel,
  OrderDate,
  CustomerMessage,
  CardImage
} from './styles'

const SelectedCard = ({ brand, number }) => {
  return <>
    {brand && number ? <>
      <CardImage src={brand} alt={`Bandeira do cartao: ${getCardBrandImageById(brand)}`} />

      <span>{number}</span>
    </> : '...'}
  </>
}
const Points = ({ earnedPoints }) => {
  return <>
    {earnedPoints ? <>
      <img src={Selo} width="40px" alt="Sele de pontos" />

      <span>{earnedPoints} pontos</span>
    </> : '...'}
  </>
}

export function Details({
  userName,
  deliveryTime,
  storeAddress,
  date,
  cardBrand,
  cardNumber,
  cpf,
  hasCpf,
	requestNumber,
  earnedPoints
}) {
  return (
    <DetailsContainer>
      <CustomerMessage>{userName},<br/> seu pedido  <span>{(requestNumber && requestNumber >= 1) ? `#${requestNumber}` : '...' }</span> foi confirmado!</CustomerMessage>
      <OrderDate>{date}</OrderDate>
      {deliveryTime ? <DeliveryEstimate>
        <ClockIcon />
        {deliveryTime}
      </DeliveryEstimate> : null}
      <DetailsItem>
        <ItemLabel>Local de retirada</ItemLabel>
        <ItemContent>
          <LocationIcon />
          <span>{storeAddress}</span>
        </ItemContent>
      </DetailsItem>
      <DetailsItem>
        <ItemLabel>Forma de pagamento</ItemLabel>
        <ItemContent iconWidth='2rem'>
          <SelectedCard brand={cardBrand} number={cardNumber} />
        </ItemContent>
      </DetailsItem>
      <DetailsItem>
        <ItemLabel>CPF na nota</ItemLabel>
        <ItemContent>
          <span>{hasCpf ? formatCpf(cpf) : '000.000.000-00'}</span>
        </ItemContent>
      </DetailsItem>
      {earnedPoints > 0 &&
        <DetailsItem>
          <ItemLabel>Pontos Ganhos</ItemLabel>
          <ItemContent iconWidth='2rem'>
            <Points earnedPoints={earnedPoints} />
          </ItemContent>
        </DetailsItem>
      }
    </DetailsContainer>
  )
}
