import React, {
	useState
} from 'react'

import LoadingGIF from '../../assets/ipiranga-loading.gif'
import { InputRounded } from '../../components'
import { Brand } from './Brand'
import {
	Wrapper,
	ContentWrapper,
	LoadingWrapper,
	LoadingImage,
	Button,
	FormFieldCPF,
	FormFieldCPFNote,
	FormFieldBirth,
	FormFieldCVV,
	FormFieldCardName,
	FormFieldCardNumber,
	FormFieldValidity,
	FormActionContainer,
} from './styles'

import { maskCard, maskCpf, maskDate, maskBirth, maskCardExpirationDate } from '../../../domain/helpers'

export function NewCard(props) {
	const {
		cardBrandTicket,
		selectCardBrandId,
		number,
		name,
		date,
		CVV,
		CPF,
		birth,
		handleNumber,
		handleName,
		handleDate,
		handleBirth,
		handleCPF,
		handleBrandId,
		handleCVV,
		handleNext: propsHandleNext
	} = props

	const [loading, setLoading] = useState(false)

	async function handleNext() {
		setLoading(true)

		await propsHandleNext()

		setLoading(false)
	}
	console.log({
		number,
		name,
		date,
		CVV,
	})
	return <Wrapper>
		{loading && <LoadingWrapper id='cards-loading'>
			<LoadingImage src={LoadingGIF} />
		</LoadingWrapper>}

		{!loading && <ContentWrapper id='cards-content'>
			<FormFieldCardNumber>
				<InputRounded mainType='input-image' maxLength={19} placeholder='Número do cartão' value={number} onChange={(event) => {
					const { value } = event.target

					handleNumber(maskCard(value))
					handleBrandId(value)
				}} />
				<Brand brandImage={selectCardBrandId} />
			</FormFieldCardNumber>
			{number && number.length === 19 ? <>
				<FormFieldCardName className="content-form">
					<InputRounded
						maxLength={255}
						placeholder='Nome do titular'
						value={name}
						onChange={(event) => {
							const { value } = event.target

							handleName(value)
						}}
					/>

				</FormFieldCardName>
				<FormFieldCPF className="content-form">
					<InputRounded maxLength={14} placeholder='CPF do titular' value={CPF} onChange={(event) => {
						const { value } = event.target

						handleCPF(maskCpf(value))
					}} />
				</FormFieldCPF>
				{cardBrandTicket ? <><FormFieldBirth className="content-form">
					<InputRounded maxLength={10} placeholder='Data de Nascimento' value={birth} onChange={(event) => {
						const { value } = event.target

						handleBirth(maskBirth(value))
					}} />
				</FormFieldBirth>
				</> : <>
					<FormFieldValidity className="content-form">
						<InputRounded 
							maxLength={5} 
							placeholder='Validade' 
							value={date} 

							onChange={(event) => {
								const { value } = event.target

								console.log('mask date')

								handleDate(maskCardExpirationDate(value))
							}} 
						/>
					</FormFieldValidity>
				</>}
				<FormFieldCVV className="content-form">
					<InputRounded maxLength={4} placeholder='CVV' value={CVV} onKeyDown={(event) => {
						const { keyCode } = event

						if (!(
							(keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) ||
							keyCode === 8 ||
							keyCode === 9 ||
							keyCode === 46 ||
							keyCode === 37 ||
							keyCode === 39
						)) {
							event.preventDefault()
						}
					}} onChange={(event) => {
						const { value } = event.target

						handleCVV(value)
					}} />
				</FormFieldCVV>
				<FormFieldCPFNote></FormFieldCPFNote>
			</> : null}
			{name && number && CVV && <FormActionContainer>
				<Button onClick={() => {
					handleNext()
				}}>Adicionar cartão</Button>
			</FormActionContainer>}
		</ContentWrapper>}
	</Wrapper>
}
