import React from 'react'

import { ReactComponent as ClockIcon } from '../../../assets/icons/icon_clock.svg'
import { ReactComponent as LocationIcon } from '../../../assets/icons/icon_checkout_address.svg'
import { selectMonth } from '../../../../domain/helpers/SelectedMonth'

import {
  getCardBrandImageById,
  formatCpf
} from '../../../../infra/utils'
import Selo from '../../../assets/icone_menu_fidelidade.png'

import {
  DetailsContainer,
  DeliveryEstimate,
  DetailsItem,
  ItemContent,
  ItemLabel,
  OrderDate,
  CustomerMessage,
  CardImage,
  FollowLink
} from './styles'

const SelectedCard = ({ brand, number }) => {
  return <>
    {brand && number ? <>
      <CardImage src={brand} alt={`Bandeira do cartao: ${getCardBrandImageById(brand)}`} />

      <span>{number}</span>
    </> : '...'}
  </>
}

const Points = ({ earnedPoints }) => {
  return <>
    {earnedPoints ? <>
      <img src={Selo} width="40px" alt="Sele de pontos" />

      <span>{earnedPoints} pontos</span>
    </> : '...'}
  </>
}

export function Details({
  userName,
  deliveryTime,
  deliveryAddress,
  date,
  cardBrand,
  cardNumber,
  cpf,
  hasCpf,
  scheduling,
  orderId,
  followLink,
  status,
  requestNumber,
  earnedPoints
}) {

  const splitDate = scheduling ? scheduling.date.split("/") : ''
  const day = splitDate ? splitDate[0] : ''
  const month = splitDate ? selectMonth(parseInt(splitDate[1])) : ''

  return (
    <DetailsContainer>
      <CustomerMessage>{userName},<br /> seu pedido <span>{(requestNumber && requestNumber >= 1) ? `#${requestNumber}` : '...'}</span> foi confirmado!</CustomerMessage>
      <OrderDate>{date}</OrderDate>
      {!scheduling && <DeliveryEstimate>
        <ClockIcon />
        {deliveryTime}
      </DeliveryEstimate>}
      {scheduling && <DetailsItem>
        <ItemLabel>Data da entrega</ItemLabel>
        <ItemContent>
          <span>{scheduling ? `${scheduling.week ? `${scheduling.week},` : ''} ${scheduling ? `${day} de ${month}` : ''}` : ''}</span>
        </ItemContent>
      </DetailsItem>}
      <DetailsItem>
        <ItemLabel>Local de entrega</ItemLabel>
        <ItemContent>
          <LocationIcon />
          <span>{deliveryAddress}</span>
        </ItemContent>
      </DetailsItem>
      <DetailsItem>
        <ItemLabel>Forma de pagamento</ItemLabel>
        <ItemContent iconWidth='2rem'>
          <SelectedCard brand={cardBrand} number={cardNumber} />
        </ItemContent>
      </DetailsItem>
      <DetailsItem>
        <ItemLabel>CPF na nota</ItemLabel>
        <ItemContent>
          <span>{hasCpf ? formatCpf(cpf) : '000.000.000-00'}</span>
        </ItemContent>
      </DetailsItem>
      {earnedPoints > 0 &&
        <DetailsItem>
          <ItemLabel>Pontos Ganhos</ItemLabel>
          <ItemContent iconWidth='2rem'>
            <Points earnedPoints={earnedPoints} />
          </ItemContent>
        </DetailsItem>
      }
      {status <= 4 ? <FollowLink href={status === 4 && followLink ? followLink : '#'} onClick={(event) => { if (!followLink || status !== 4) { event.preventDefault(); return false } }} className={`${!followLink && status !== 4 ? 'disabled' : ''}`} target={'_blank'}>Acompanhe a entrega</FollowLink> : <></>}
    </DetailsContainer>
  )
}
