import React, {
	useEffect,
	useState
} from 'react'

import {
	CustomIcon
} from '../../components'
import history from '../../../history'

import {
	calculateCartTotal
} from '../../../domain/helpers'

import { ReactComponent as EmptyBasketIcon } from '../../assets/icons/icon_empty_basket.svg'

import {
	Wrapper,
	ListWrapper,
	ListItemWrapper,
	H2,
	ProductWrapper,
	ActionsWrapper,
	SummaryWrapper,
	Paragraph,
	Span,
	Button,
	QuantityControlWrapper,
	EmptyBasketContainer,
	GreetingsTitle,
	SimpleQuantity
} from './styles'
import { LoyaltyPoints } from '../LoyaltyPoints'

const calculateQuantityTotal = (items) => {
	const total = items && items.reduce((acc, item) => acc + item.quantity, 0)

	return total
}

const QuantityControl = ({
	quantity,
	uuid,
	name,
	value,
	handleQuantity,
	dataTotal,
	URLParameters = ''
}) => {
	return <QuantityControlWrapper>
		<Button onClick={() => {
			if (quantity === 1) {
				handleQuantity({
					uuid,
					quantity: 0,
					name,
					value
				})

				if (dataTotal && dataTotal.length === 1) {
					history.push(`/${URLParameters}`)
				}

				return
			}

			handleQuantity({
				uuid,
				quantity: -1
			})
		}}>
			<CustomIcon name={quantity === 1 ? 'Trash' : 'Minus'} width='11px' />
		</Button>
		<Span>{quantity}</Span>
		<Button onClick={() => {
			if (quantity === 999) {
				return
			}

			handleQuantity({
				uuid,
				quantity: 1
			})
		}}>
			<CustomIcon name='Plus' width='11px' />
		</Button>
	</QuantityControlWrapper>
}

export function List(props) {
	const {
		data,
		deliveryFee,
		storeName,
		handleQuantity: propsHandleQuantity,
		modalityId,
		coupon,
		isQuantityEditable = true,
		userName,
		mode,
		setProductsRemoved,
		URLParameters,
		setAbove,
		handleGTM,
		storeLoyaltyPoints,
		points,
		minValueLoyaltyRequired
	} = props

	const {
		value: couponValue,
		minValue: couponMinimumValue,
		type,
		typeValueModalityDelivery,
		valueModalityDelivery,
		valueMaxModalityDelivery,
		valueMinModalityDelivery
	} = coupon

	const [loaded, setLoaded] = useState(false)
	const [cartSubtotalTotal, setCartSubtotalTotal] = useState(0)
	const [cartTotal, setCartTotal] = useState(0)
	const [itemsQuantity, setItemsQuantity] = useState(1)
	const [percentual, setPercentual] = useState(0)
	const [finalCupomValue, setFinalCupomValue] = useState(0)
	const [finalPointsMinValue, setFinalPointsMinValue] = useState(0)

	useEffect(() => {
		if (type !== 2 && couponValue >= calculateCartTotal(data)) {
			setFinalCupomValue(calculateCartTotal(data))
			setAbove(false)
		} else {
			setFinalCupomValue(couponValue)
			setAbove(true)
		}
	}, [couponValue, data, setAbove, type])

	useEffect(() => {
		if (!loaded) {
			setLoaded(true)
		}

		setCartSubtotalTotal(calculateCartTotal(data))

		setItemsQuantity(calculateQuantityTotal(data))

		if (coupon && finalCupomValue) {
			if (type === 2) {
				const percentualCalculate = (calculateCartTotal(data) * finalCupomValue) / 100
				setPercentual(percentualCalculate)
				setCartTotal(calculateCartTotal(data) - percentualCalculate + deliveryFee)

				return () => ({})
			}

			setCartTotal(calculateCartTotal(data) - finalCupomValue + deliveryFee)

			return () => ({})
		}

		setCartTotal(calculateCartTotal(data) + deliveryFee)

		return () => { }
	}, [
		data,
		itemsQuantity,
		storeName,
		loaded,
		couponValue,
		couponMinimumValue,
		cartSubtotalTotal,
		coupon,
		deliveryFee,
		typeValueModalityDelivery,
		valueMaxModalityDelivery,
		valueMinModalityDelivery,
		valueModalityDelivery,
		type,
		finalCupomValue
	])

	useEffect(() => {
		if (minValueLoyaltyRequired && cartSubtotalTotal > storeLoyaltyPoints.minValue) {
			const calc = cartSubtotalTotal / storeLoyaltyPoints.minValue

			const [int = 0] = calc.toString().split('.').map((num) => parseInt(num));
			setFinalPointsMinValue(int * points)
		}
	}, [
		minValueLoyaltyRequired,
		points,
		cartSubtotalTotal,
		storeLoyaltyPoints
	])

	function handleQuantity(args) {
		propsHandleQuantity && propsHandleQuantity(args)

		if (args.quantity === 0) {
			const product = {
				'name': args.name,
				'id': args.id,
				'value': args.promotionalValue ? args.promotionalValue : args.value,
				'brand': 'Seara',
				'category': args.category && args.category.name,
				'variant': '',
				'quantity': args.quantity
			}

			setProductsRemoved(product)
		}
		//GTM 3.0 - Evento de Remover/Aletrar produto do carrrinho
		const dataGTM = {
			'event': `remove_to_cart`,
			'ecommerce': {
				'currency': 'BRL',
				'store': storeName,
				'items': [{
					'item_name': args.name,
					'item_id': args.id,
					'price': args.promotionalValue ? args.promotionalValue : args.value,
					'discount': null,
					'categoria': (args.category && args.category.name) ?? null,
					'quantity': 1
				}]
			}
		}
		//console.warn(dataGTM)
		handleGTM(dataGTM)
	}

	return <Wrapper id='cart-list'>
		{mode === 'simple' ? null : <GreetingsTitle>
			Olá, <span>{userName}</span><br /> pronto para concluir<br /> seu pedido?
		</GreetingsTitle>}

		{data && data.length ? <H2>Resumo do Pedido</H2> : null}

		{data && data.length ? <ListWrapper>
			{data.map((item, index) => {
				const key = `cart-list-item-${index}`

				const {
					//id,
					uuid,
					quantity,
					name,
					value,
					selectedAdditionals,
					promotionalValue,
					description
				} = item
				const valueFinalProduct = !promotionalValue ? value : promotionalValue
				const selectedAdditionalsGroups = selectedAdditionals && selectedAdditionals.length && selectedAdditionals.map((mappedItem) => {
					const items = mappedItem.items || [];
					const selectedAdditionalsMaximum = items.filter(filteredItem => filteredItem.calcType === 3)
					const selectedAdditionalsAverage = items.filter(filteredItem => filteredItem.calcType === 2)
					const selectedAdditionalsFixes = items.filter(filteredItem => filteredItem.calcType === 1)

					const additionalsMaximumTotal = (selectedAdditionalsMaximum && selectedAdditionalsMaximum.length && Math.max.apply(Math, selectedAdditionalsMaximum.map((selectedAdditionalsMaximumItem) => selectedAdditionalsMaximumItem.value))) || 0
					const additionalsAverageTotal = selectedAdditionalsAverage && selectedAdditionalsAverage.length ? (selectedAdditionalsAverage.reduce((acc, current) => Number(acc + current.value), [])) / selectedAdditionalsAverage.length : 0
					const additionalsFixesTotal = selectedAdditionalsFixes && selectedAdditionalsFixes.length ? selectedAdditionalsFixes.reduce((acc, current) => {
						if (current.quantity > 1) {
							return Number(acc + (current.value * current.quantity))
						}

						return Number(acc + current.value)
					}, []) : 0

					const description = (mappedItem.items && mappedItem.items.map((descriptionMappedItem) => {
						const {
							name,
							quantity
						} = descriptionMappedItem

						return (quantity > 1 ? `${quantity}x ${name}` : name).trim();
					})).filter(filteredItem => filteredItem.trim())

					return {
						value: additionalsMaximumTotal + additionalsAverageTotal + additionalsFixesTotal,
						description: description.join(', ')
					}
				})

				const selectedAdditionalsGroupsTotal = (selectedAdditionalsGroups && selectedAdditionalsGroups.length && selectedAdditionalsGroups.reduce((acc, current) => Number(acc + current.value), [])) || 0
				const selectedAdditionalsGroupsDescription = (selectedAdditionalsGroups && selectedAdditionalsGroups.length ? selectedAdditionalsGroups.reduce((acc, current) => `${acc ? `${acc}, ` : ''}${current.description}`, '') : '') || 0
				const total = quantity * (valueFinalProduct + (selectedAdditionalsGroupsTotal && selectedAdditionalsGroupsTotal))
				const finalDescription = selectedAdditionals && selectedAdditionals.length ? selectedAdditionalsGroupsDescription : ''

				return (
					<ListItemWrapper key={key}>
						<ProductWrapper>
							<Paragraph className='name'>{name}</Paragraph>
							{finalDescription || description ? <Paragraph className='description'>{finalDescription || description}</Paragraph> : null}
							<Paragraph className='value'>{`R$ ${total && total.toFixed(2).replace('.', ',')}`}</Paragraph>
						</ProductWrapper>
						<ActionsWrapper>
							{isQuantityEditable ?
								<QuantityControl
									uuid={uuid}
									name={name}
									value={value}
									quantity={quantity}
									handleQuantity={handleQuantity}
									dataTotal={data}
									URLParameters={URLParameters}
								/> :
								<SimpleQuantity>{quantity}</SimpleQuantity>
							}
						</ActionsWrapper>
					</ListItemWrapper>
				)
			})}
		</ListWrapper> : null}

		{!(data && data.length) ?
			<EmptyBasketContainer>
				<EmptyBasketIcon />
				<h4>Sua cesta está vazia!</h4>
				<p>Adicione seus itens favoritos aqui.</p>
			</EmptyBasketContainer> : null
		}

		{data && data.length ?
			<SummaryWrapper>
				<Paragraph className='subtotal'><Span className='label'>Subtotal:</Span> <Span className='value'>{`R$ ${cartSubtotalTotal.toFixed(2).replace('.', ',')}`}</Span></Paragraph>
				{cartSubtotalTotal && finalCupomValue ? <Paragraph className='discount'><Span className='label'>Desconto:</Span> <Span className='value'>{`-R$ ${type === 2 ? percentual.toFixed(2).replace('.', ',') : finalCupomValue.toFixed(2).replace('.', ',')}`}</Span></Paragraph> : null}
				<Paragraph className='fee'><Span className='label'>{modalityId === 4 ? 'Taxa de entrega' : 'Taxa de retirada'}:</Span> <Span className='value'>{modalityId === 4 && deliveryFee ? `R$ ${(deliveryFee.toFixed(2).replace('.', ','))}` : 'Grátis'}</Span></Paragraph>
				<Paragraph className='total'><Span className='label'>TOTAL:</Span> <Span className='value total'>{`R$ ${(cartTotal).toFixed(2).replace('.', ',')}`}</Span></Paragraph>
			</SummaryWrapper>
			: null}
		{minValueLoyaltyRequired && storeLoyaltyPoints && points > 0 && cartSubtotalTotal > storeLoyaltyPoints.minValue ? <LoyaltyPoints points={finalPointsMinValue} /> : null}
		{data && data.length > 0 && !minValueLoyaltyRequired && storeLoyaltyPoints && points > 0 && <LoyaltyPoints points={points} />}
	</Wrapper>
}
