import { actionTypes } from '.'

import {
  setUserCard
} from '../../redux/actions/main'

import {
  loading,
  signUp,
  menuActions
} from '..'
import { LIMITE_STORES } from '../../config'

export const setStore = (store) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_STORE,
    payload: store
  })
}

export const setStoreModalities = (storeModalities) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_STORE_MODALITIES,
    payload: storeModalities
  })
}

export const setStoresListRequest = (storesListRequest) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_STORES_LIST_REQUEST,
    payload: storesListRequest
  })
}

export const setLoyaltyPoints = (loyalty) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOYALTY_POINTS,
    payload: loyalty
  })
}

export const setSelectedStoreId = (selectedStoreId) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_STORE_ID,
    payload: selectedStoreId
  })
}

export const setStoreFees = (storeFees) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_STORE_FEES,
    payload: storeFees
  })
}

export const setStores = (stores) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_STORES,
    payload: stores
  })
}

export const setStoresPagination = (pagination) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PAGINATION,
    payload: pagination
  })
}

export const setSelectedCategory = (category) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_CATEGORY,
    payload: category
  })
}

export const cleanStore = () => async (dispatch) => {
  dispatch(setUserCard(null))
  dispatch(signUp.setSignUp(null))
}

export const fetchStores = (callback) => (dispatch, getState, container) => {
  const callbackError = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => { }
  const callbackSuccess = callback && callback[1] && typeof callback[1] === 'function' ? callback[1] : () => { }

  const latitude = getState().address && getState().address.latitude
  const longitude = getState().address && getState().address.longitude
  const modalityId = getState().main.modality && getState().main.modality.id
  const searchText = getState().main.search && getState().main.search.text
  const paginationOffset = getState().main.pagination && getState().main.pagination.offset
  const storesList = (getState().store.stores && getState().store.stores.list) || []
  const stateLoading = (getState().main && getState().loading.loading) || []
  const limiteStores = LIMITE_STORES || 0

  try {
    if (stateLoading && stateLoading.includes('stores-list')) {
      return
    }

		//dispatch(loading.setLoading('stores-list'))

    const data = {
      latitude,
      longitude,
      modalityId,
      searchText,
      paginationOffset,
      limiteStores
    }

    const actions = {
      setStoresPagination,
      setLoading: loading.setLoading
    }

    const done = () => {
      dispatch(loading.setLoading({ item: 'stores-list', delete: true }))
    }

    return container.fetchStores({
      onSuccess: ({ stores }) => {
				//console.log({stores})
        dispatch(setStoresPagination({
          offset: paginationOffset,
          total: (stores && stores.remaining) || 0
        }))

        if (!paginationOffset && stores.list && stores.list.length) {
          dispatch(setStores([...stores.list]))

          callbackSuccess && callbackSuccess()

          //done()

          return
        }

        if (paginationOffset && stores && stores.list && stores.list.length && storesList && storesList.length) {
          dispatch(setStores([
            ...storesList,
            ...stores.list
          ]))

          callbackSuccess && callbackSuccess()

          //done()

          return
        }

        dispatch(setStores([]))

        //done()

        return
      },
      onError: () => {
        dispatch(setStoresPagination({
          offset: 0,
          total: 0
        }))

        storesList && storesList.length && dispatch(setStores([...storesList]))

        callbackError && callbackError()

        done()
      },
      onEmpty: () => {
        dispatch(setStoresPagination({
          offset: 0,
          total: 0
        }))

        storesList && storesList.length && dispatch(setStores([...storesList]))

        callbackError && callbackError()

        done()
      },
      dispatch,
      data,
      actions,
      getState
    })
  } catch (e) {
    console.log(e)

    dispatch(setStores([]))

    callbackError && callbackError()
  }
}

export const fetch = (callback) => (dispatch, getState, container) => {
  const callbackErrorFilter = callback && callback.filter(filteredItem => filteredItem.name === 'store' && filteredItem.type === 'error')
  const callbackError = callbackErrorFilter && callbackErrorFilter[0] && callbackErrorFilter[0].callback
  const callbackSuccessFilter = callback && callback.filter(filteredItem => filteredItem.name === 'store' && filteredItem.type === 'success')
  const callbackSuccess = callbackSuccessFilter && callbackSuccessFilter[0] && callbackSuccessFilter[0].callback
  const callbackDataFilter = callback && callback.filter(filteredItem => filteredItem.name === 'store' && filteredItem.type === 'data')
  const callbackData = (callbackDataFilter && callbackDataFilter[0] && callbackDataFilter[0].data) || {}
  const callbackModalityFilter = callback && callback.filter(filteredItem => filteredItem.name === 'store' && filteredItem.type === 'modality')
  const callbackModality = (callbackModalityFilter && callbackModalityFilter[0] && callbackModalityFilter[0].data) || {}
  const pathnames = window.location.pathname.split('/')
  const stateLoading = (getState().main && getState().loading.loading) || []
  const modalityId = callbackModality.pickup ? 2 : getState().main.modality && getState().main.modality.id
  const latitude = getState().address.latitude
  const longitude = getState().address.longitude
  const accessToken = getState().user.accessToken
  const userAddressId = getState().address.address && getState().address.address.id
  const storeId = pathnames && pathnames[1] ? pathnames[1] : (getState().main.store && getState().main.store.id) || (getState().main.receipt && getState().main.receipt.store && getState().main.receipt.store.id)
  const selectedStoreId = (getState().store && getState().store.selectedStoreId) || null
  const selectedCategoryIndex = (getState().store.store && getState().store.store.selectedCategory && getState().store.store.selectedCategory.index) || 0

  const done = () => {
    dispatch(loading.setLoading({ item: 'store', delete: true }))
  }

  try {
    if (stateLoading && stateLoading.includes('store') && !callbackData.force) {
      return
    }

    dispatch(loading.setLoading('store'))

    const data = {
      latitude,
      longitude,
      modalityId,
      userAddressId,
      accessToken,
      storeId,
      selectedStoreId,
      selectedCategoryIndex,
      ...callbackData
    }

    return container.fetchStore({
      onSuccess: ({ store }) => {
        if (modalityId === 2 && store && store.id && store.name) {
          const {
            categories,
            ...newMappedResult
          } = store

          const selectedCategory = {
            id: categories && categories[0] && categories[0].id,
            name: categories && categories[0] && categories[0].name,
            index: 0,
            selectedProductsCategories: false,
            openModalCategory: false
          }

          dispatch(setStore({
            ...newMappedResult,
            selectedCategory
          }))

          dispatch(menuActions.set(categories))

          callbackSuccess && callbackSuccess()

          done()
        } else if (modalityId === 4 && store && store.id && store.name) {
          const {
            categories
          } = store

          const selectedCategory = {
            id: categories && categories[0] && categories[0].id,
            name: categories && categories[0] && categories[0].name,
            index: 0,
            selectedProductsCategories: false,
            openModalCategory: false
          }

          const {
            ...newMappedResult
          } = store

          dispatch(setStore({
            ...newMappedResult,
            selectedCategory
          }))

          dispatch(menuActions.set(categories))

          callbackSuccess && callbackSuccess()

          done()
        } else {
          throw new Error()
        }

        return
      },
      onError: () => {
        callbackError && callbackError()

        done()
      },
      onEmpty: () => {
        callbackError && callbackError()

        done()
      },
      data,
      getState
    })
  } catch (e) {
    console.log(e)

    if (e.loading === true) {
      console.warn(`storeActions.fetch is already loading`)

      return
    } else {
      callbackError && callbackError()

      done()
    }
  }
}
