import React, {
	useState,
	useCallback,
	useEffect
} from 'react'
import {
	connect,
	useDispatch
} from 'react-redux'
import PropTypes from 'prop-types'

import { MULTI_STORE } from '../../../config'

import history from '../../../history'

import {
	pipe,
	GetContent,
	maskCep
} from '../../../domain/helpers'

import {
	addressActions,
	userAddress,
	store as storeActions,
	menuActions
} from '../../../state'

import {
	setNotification,
	setUserHistory,
	setModality,
	removeProduct,
	addProduct,
	setOpenCart
} from '../../../redux/actions/main'

import {
	PostalCodeForm
} from './../PostalCode'

import {
	CustomInput,
	CustomIcon,
	CustomLoading,
} from '../../components'

import { ReactComponent as WorkCategoryIcon } from '../../assets/icons/icon_address_work_category.svg'
import { ReactComponent as FavoriteCategoryIcon } from '../../assets/icons/icon_address_favorite_category.svg'
import { ReactComponent as HomeCategoryIcon } from '../../assets/icons/icon_address_home_category.svg'

import {
	Wrapper,
	ContentWrapper,
	H3,
	NewAdressForm,
	FormFieldAddress,
	FormFieldAddressAdditional,
	FormFieldAddressNumber,
	FormFieldCity,
	FormFieldDistrict,
	FormFieldReference,
	FormFieldState,
	SaveAddressLabel,
	AddressCategory,
	AddressCategoryList,
	SubmitButton,
	CloseButton
} from './styles'
import { errorOptions, successOptions } from '../../../infra/utils/styleNotification'
import { useSnackbar } from 'react-simple-snackbar'

const addressCategoryTypes = [
	{ id: 0, label: 'Casa', icon: HomeCategoryIcon },
	{ id: 1, label: 'Trabalho', icon: WorkCategoryIcon },
	{ id: 2, label: 'Favorito', icon: FavoriteCategoryIcon }
]

const Context = React.createContext({})

const requiredFields = [{
	name: 'main',
	notification: {
		message: 'O endereço é obrigatório'
	},
}, {
	name: 'number',
	notification: {
		message: 'O número é obrigatório'
	},
}, {
	name: 'cep',
	notification: {
		message: 'O CEP é obrigatório'
	},
}, {
	name: 'neighborhood',
	notification: {
		message: 'O bairro é obrigatório'
	},
}, {
	name: 'city',
	notification: {
		message: 'A cidade é obrigatória'
	},
}, {
	name: 'state',
	notification: {
		message: 'O estado é obrigatório'
	}
}]

function NewAddressPage(props) {
	const dispatch = useDispatch()
	const setStoresListRequest = (args) => dispatch(storeActions.setStoresListRequest(args))

	const {
		addressByCEP,
		address,
		fetchGeolocationByAddress,
		setAddress,
		setUserHistory,
		setNotification,
		fetchAddressByCEP,
		setCEP,
		updateUserAddress,
		addUserAddress,
		notification,
		postalCode: cep,
		handleCloseDialogAddress,
		accessToken,
		setModality,
		userHistory,
		getStoreFees,
		URLParameters,
		setAddressPostalCodeRequest,
		setAddressRequest,
		closeButton,
		setNoAddressesList,
		userCallback,
		removeProduct,
		addProduct,
		cartClicked,
		setSelectedProduct,
	} = props

	const displayedCEP = cep
	const formattedCEP = `${displayedCEP ? maskCep(displayedCEP.replace(/-/g, '')) : ''}`
	const [loading, setLoading] = useState(false)
	const [category, setCategory] = useState(0)
	const [warningPostalCode, setWarningPostalCode] = useState(null)
	const [postalCode, setPostalCode] = useState(formattedCEP)
	const [values, setValues] = useState({ ...addressByCEP })
	const [openSuccessSnackbar] = useSnackbar(successOptions({ modal: true }))
	const [openErrorSnackbar] = useSnackbar(errorOptions({ modal: true }))
	const {
		main,
		number,
		neighborhood,
		city,
		state,
		complement,
		reference
	} = values

	useEffect(() => {
		setValues({ ...address })
	}, [
		address
	])

	const handlePostalCodeChange = useCallback((event) => {
		const {
			value
		} = event.target

		setPostalCode(value)
	}, [setPostalCode])

	async function handlePostalCodeClick() {
		setNotification({})

		if (formattedCEP && formattedCEP.length < 9) {
			setWarningPostalCode({
				type: 'warning',
				message: 'O CEP digitado é inválido! Tente outro!'
			})

			return
		}

		setCEP(postalCode)

		await fetchAddressByCEP([{
			name: 'address-by-cep',
			type: 'error',
			callback: () => {
				setNotification({
					type: 'error',
					message: 'Endereço fora da área de serviço! :('
				})

				setAddress({})
				setAddressPostalCodeRequest(false)
				setAddressRequest(true)

				console.warn('NewaddressActions.fetchAddressByCEP.error')
			}
		}, {
			name: 'address-by-cep',
			type: 'success',
			callback: () => {
				setAddressPostalCodeRequest(false)
				setAddressRequest(true)
			}
		}])

		setWarningPostalCode(null)
	}

	function handleInput(event) {
		const {
			name,
			value
		} = event.target

		const newValue = {
			[name]: value
		}

		setValues({
			...values,
			...newValue
		})
	}

	async function handleSave() {
		setLoading(true)
		const address = { ...values }

		const invalidFields = requiredFields.filter((requiredField) => !address[requiredField.name]);
	
		if (invalidFields.length) {
			for (const invalidField of invalidFields) {
				invalidField.name !== 'cep' && setNotification({
					...invalidField.notification,
					type: `new-address-${invalidField.name}-warning`
				})
			}

			const isCEPInvalid = !!invalidFields.find(invalidField => invalidField.name === 'cep')
			if (isCEPInvalid) {
				setAddress({
					...address,
					cep: `${formattedCEP.substr(0, 5)}-${formattedCEP.substr(5, 4)}`
				})
			}

			setLoading(false)
			return
		}

		setNotification(null)

		console.warn({ address })

		// User is not logged and address is full filled
		if (!address.id && !accessToken && address.main && address.number && address.neighborhood && address.cep && address.city && address.state) {
			const newAddressByCep = {
				...addressByCEP,
				...address
			}

			setAddress({
				...newAddressByCep,
				verified: false
			})

			if (MULTI_STORE === 'true') {
				openSuccessSnackbar('Endereço cadastrado com sucesso!')

				const newAddressByCep = {
					...addressByCEP,
					...address
				}

				setAddress({
					...newAddressByCep,
					verified: false
				})


				await fetchGeolocationByAddress([{
					name: 'geolocation-by-address',
					type: 'error',
					callback: () => { }
				}, {
					name: 'geolocation-by-address',
					type: 'success',
					callback: () => { }
				}])
				
				setTimeout(() => {
					setNotification(null)
				}, 2000)

				handleCloseDialogAddress()
				setAddressRequest(false)
				setStoresListRequest(true)
				setLoading(false)

				return
			}

			await getStoreFees([{
				name: 'store-fees',
				type: 'error',
				callback: () => {
					openErrorSnackbar('Endereço fora da área de serviço! :(', 7000)

					setAddress({})
					setAddressRequest(true)
					setAddressPostalCodeRequest(false)
					setLoading(false)
				}
			}, {
				name: 'store-fees',
				type: 'success',
				callback: () => {

					const newAddressByCep = {
						...addressByCEP,
						...address
					}

					setAddress({
						...newAddressByCep,
						verified: true
					})

					openSuccessSnackbar('Endereço cadastrado com sucesso!')

					setTimeout(() => {
						setNotification(null)

						if (handleCloseDialogAddress && typeof handleCloseDialogAddress === 'function') {
							handleCloseDialogAddress()
						} else {
							const {
								back
							} = userHistory

							if (back) {
								history.push(`/${back}`)
							} else {
								history.push(`/`)
							}
						}

						if (userCallback && userCallback.name && userCallback.name === 'add-product') {
							removeProduct(userCallback.data)
							addProduct(userCallback.data)

							cartClicked()
							setSelectedProduct(null)
							history.push('/')
						}

						setAddressRequest(false)
						setNoAddressesList(false)
						setLoading(false)
					}, 2000)
				}
			}])
			// User is logged and address is not selected
		} else if (!address.id && address.main && accessToken) {
			const newAddressByCep = {
				...addressByCEP,
				...address
			}

			setAddress({
				...newAddressByCep,
				verified: false
			})

			if (MULTI_STORE === 'true') {
				openSuccessSnackbar('Endereço cadastrado com sucesso!')

				await fetchGeolocationByAddress([{
					name: 'geolocation-by-address',
					type: 'error',
					callback: () => { }
				}, {
					name: 'geolocation-by-address',
					type: 'success',
					callback: () => { }
				}])
				
				setTimeout(() => {
					setNotification(null)
				}, 2000)

				handleCloseDialogAddress()
				setAddressRequest(false)
				setStoresListRequest(true)
				setLoading(false)

				return
			}
			await getStoreFees([{
				name: 'store-fees',
				type: 'error',
				callback: () => {
					openErrorSnackbar('Endereço fora da área de serviço! :(', 7000)

					setAddress({})
					setAddressRequest(true)
					setAddressPostalCodeRequest(false)
					setLoading(false)
				}
			}, {
				name: 'store-fees',
				type: 'success',
				callback: async () => {
					await fetchGeolocationByAddress([{
						name: 'geolocation-by-address',
						type: 'error',
						callback: () => { }
					}, {
						name: 'geolocation-by-address',
						type: 'success',
						callback: () => { }
					}])

					const newAddressByCep = {
						...addressByCEP,
						...address
					}

					setAddress({
						...newAddressByCep,
						verified: true
					})

					await addUserAddress([() => {
						openErrorSnackbar('Não foi possível cadastrar seu endereço. Tente novamente.', 7000)

						if (handleCloseDialogAddress && typeof handleCloseDialogAddress === 'function') {
							handleCloseDialogAddress()
						} else {
							const {
								back
							} = userHistory

							if (back) {
								history.push(`/${back}`)
							} else {
								history.push(`/`)
							}
						}

						setAddressRequest(false)
						setNoAddressesList(false)
						setLoading(false)
					}, () => {
						console.warn('Address created')

						setModality({ id: 4 })

						if (handleCloseDialogAddress && typeof handleCloseDialogAddress === 'function') {
							handleCloseDialogAddress()
						} else {
							const {
								back
							} = userHistory

							if (back) {
								history.push(`/${back}`)
							} else {
								history.push(`/`)
							}
						}

						if (userCallback && userCallback.name && userCallback.name === 'add-product') {
							removeProduct(userCallback.data)
							addProduct(userCallback.data)

							setOpenCart(true)
							setSelectedProduct(null)
							history.push('/')
						}

						setNotification({})
						setAddressRequest(false)
						setNoAddressesList(false)
						setLoading(false)
					}])
				}
			}])
			// User is logged and address is selected
		} else if (address && address.id && accessToken) {
			const newAddressByCep = {
				...addressByCEP,
				...address
			}

			setAddress({
				...newAddressByCep,
				verified: false
			})

			if (MULTI_STORE === 'true') {
				openSuccessSnackbar('Endereço cadastrado com sucesso!')

				await fetchGeolocationByAddress([{
					name: 'geolocation-by-address',
					type: 'error',
					callback: () => { }
				}, {
					name: 'geolocation-by-address',
					type: 'success',
					callback: () => { }
				}])
				
				setTimeout(() => {
					setNotification(null)
				}, 2000)

				handleCloseDialogAddress()
				setAddressRequest(false)
				setStoresListRequest(true)
				setLoading(false)

				return
			}
			
			await getStoreFees([{
				name: 'store-fees',
				type: 'error',
				callback: () => {
					openErrorSnackbar('Endereço fora da área de serviço! :(', 7000)

					setAddress({})
					setAddressRequest(true)
					setAddressPostalCodeRequest(false)
					setLoading(false)
				}
			}, {
				name: 'store-fees',
				type: 'success',
				callback: async () => {
					await updateUserAddress([() => {
						setUserHistory({
							next: 'addresses',
							back: 'addresses'
						})

						openErrorSnackbar('Não foi possível cadastrar seu endereço. Tente novamente.', 7000)

						handleCloseDialogAddress()
						setLoading(false)
					}, () => {
						handleCloseDialogAddress()

						const {
							back
						} = userHistory

						const newAddressByCep = {
							...addressByCEP,
							...address
						}

						setAddress({
							...newAddressByCep,
							verified: true
						})

						if (back && back !== '/') {
							history.push(`/${back}${URLParameters}`)
						}
					}])

					if (userCallback && userCallback.name && userCallback.name === 'add-product') {
						removeProduct(userCallback.data)
						addProduct(userCallback.data)

						setOpenCart(true)
						setSelectedProduct(null)
						history.push('/')
					}

					setAddressRequest(false)
					setNoAddressesList(false)
					setLoading(false)
				}
			}])
		}
	}

	function handleCategory(args) {
		postType(args)
	}

	function postType(args) {
		setCategory(args)
		setAddress({
			...addressByCEP,
			...address,
			...values,
			type: args
		})
	}

	return <Wrapper>
		{(!notification || !notification.type) && closeButton && <CloseButton className='close-button' onClick={() => {
			if (handleCloseDialogAddress && typeof handleCloseDialogAddress === 'function') {
				handleCloseDialogAddress()
			} else {
				const {
					back
				} = userHistory

				if (back && back !== 'addresses') {
					history.push(`/${back}`)
				} else {
					history.push(`/`)
				}
			}
		}}>
			<CustomIcon name='Times' />
		</CloseButton>}

		<ContentWrapper id='new-address-content'>
			<H3>Onde deseja receber seu pedido?</H3>

			<NewAdressForm method='post' onSubmit={(event) => {
				event.preventDefault()
			}}>
				<PostalCodeForm
					postalCodeValue={postalCode}
					warningPostalCode={warningPostalCode}
					errorPostalCode={notification && notification.type === 'error' ? {
						type: 'error'
					} : {}}
					handlePostalCodeChange={handlePostalCodeChange}
					handlePostalCodeClick={handlePostalCodeClick}
				/>
				<FormFieldAddress>
					<CustomInput
						mainType='input'
						type='text'
						name='main'
						value={(main)}
						onChange={handleInput}
						notification={notification}
						maxLength={150}
						placeholder="Endereço"
						disabled={loading ? true : false}
					/>
				</FormFieldAddress>
				<FormFieldAddressNumber>
					<CustomInput
						mainType='input'
						className='half'
						type='number'
						name='number'
						value={number}
						onChange={handleInput}
						notification={notification}
						maxLength={10}
						disabled={loading ? true : false}
						placeholder="Número" />
				</FormFieldAddressNumber>
				<FormFieldAddressAdditional>
					<CustomInput
						mainType='input'
						type='text'
						className='half'
						name='complement'
						value={complement}
						onChange={handleInput}
						notification={notification}
						maxLength={150}
						disabled={loading ? true : false}
						placeholder="Complemento" />
				</FormFieldAddressAdditional>
				<FormFieldDistrict>
					<CustomInput
						mainType='input'
						type='text'
						className='half'
						name='neighborhood'
						value={neighborhood}
						onChange={handleInput}
						notification={notification}
						disabled={loading ? true : false}
						maxLength={60}
						placeholder="Bairro" />
				</FormFieldDistrict>
				<FormFieldCity>
					<CustomInput
						mainType='input'
						type='text'
						className='half'
						name='city'
						value={city}
						onChange={handleInput}
						notification={notification}
						maxLength={50}
						disabled={loading ? true : false}
						readOnly={!!city || false}
						placeholder="Cidade" />
				</FormFieldCity>
				<FormFieldState>
					<CustomInput
						mainType='input'
						type='text'
						className='half'
						name='state'
						value={state}
						onChange={handleInput}
						notification={notification}
						maxLength={60}
						disabled={loading ? true : false}
						readOnly={!!state || false}
						placeholder="Estado" />
				</FormFieldState>
				<FormFieldReference>
					<CustomInput
						mainType='input'
						type='text'
						name='reference'
						value={reference}
						onChange={handleInput}
						disabled={loading ? true : false}
						notification={notification}
						placeholder='Referência para o entregador' />
				</FormFieldReference>
				<SaveAddressLabel>Gravar endereço?</SaveAddressLabel>
				<AddressCategoryList>
					{addressCategoryTypes.map(({ label, icon: Icon, id }, index) => {
						return <AddressCategory key={index} className={(category === id) ? 'category-active' : ''} onClick={(event) => {
							handleInput(event)
							handleCategory(id)
						}}>
							<Icon />
							<span>{label}</span>
						</AddressCategory>
					}
					)}
				</AddressCategoryList>
				<SubmitButton onClick={() => {
					handleSave()
				}}>{loading ? <CustomLoading color={'#fff'} type={'spin'} id='default-loading' height={30} width={30} /> :
					'Salvar endereço'}</SubmitButton>
			</NewAdressForm>
		</ContentWrapper>
	</Wrapper>
}

NewAddressPage.propTypes = {
	addressByCEP: PropTypes.shape({
		id: PropTypes.number,
		main: PropTypes.string,
		number: PropTypes.string,
		neighborhood: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.string,
		complement: PropTypes.string,
		reference: PropTypes.string,
		cep: PropTypes.string,
		latitude: PropTypes.number,
		longitude: PropTypes.number
	}),
	address: PropTypes.shape({
		id: PropTypes.number,
		main: PropTypes.string,
		number: PropTypes.string,
		neighborhood: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.string,
		complement: PropTypes.string,
		reference: PropTypes.string,
		cep: PropTypes.string,
		latitude: PropTypes.number,
		longitude: PropTypes.number
	}),
	fetchGeolocationByAddress: PropTypes.func,
	setAddress: PropTypes.func,
	setUserHistory: PropTypes.func,
	setNotification: PropTypes.func,
	fetchAddressByCEP: PropTypes.func,
	setCEP: PropTypes.func,
	updateUserAddress: PropTypes.func,
	addUserAddress: PropTypes.func,
	userAddress: PropTypes.shape({}),
	notification: PropTypes.shape({
		type: PropTypes.string,
		message: PropTypes.string,
		name: PropTypes.string
	}),
	postalCode: PropTypes.string,
	handleCloseDialogAddress: PropTypes.func,
	accessToken: PropTypes.string,
	setModality: PropTypes.func,
	userHistory: PropTypes.shape({
		back: PropTypes.string,
		next: PropTypes.string
	}),
	getStoreFees: PropTypes.func,
	URLParameters: PropTypes.string,
	setAddressPostalCodeRequest: PropTypes.func,
	setAddressRequest: PropTypes.func,
	closeButton: PropTypes.bool,
	setNoAddressesList: PropTypes.func,
	userCallback: PropTypes.shape({
		name: PropTypes.string,
		data: PropTypes.shape({}),
	}),
	removeProduct: PropTypes.func,
	addProduct: PropTypes.func,
	cartClicked: PropTypes.func,
	setSelectedProduct: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		accessToken: state.user.accessToken,
		userAddresses: state.userAddress.userAddresses || [],
		addressByCEP: (state.address.addressByCEP) || {},
		address: (state.address.address) || {},
		postalCode: (state.address.cep) || null,
		latitude: (state.main.latitude) || null,
		longitude: (state.main.longitude) || null,
		userHistory: (state.main.userHistory) || {},
		notification: state.main.notification || {},
		URLParameters: (state.main.URLParameters) || '',
		userCallback: (state.user.callback) || {}
	}
}

const GetConnection = connect(mapStateToProps, {
	fetchStore: storeActions.fetch,
	fetchGeolocationByAddress: addressActions.fetchGeolocationByAddress,
	setAddress: addressActions.setAddress,
	updateUserAddress: userAddress.update,
	addUserAddress: userAddress.add,
	setCEP: addressActions.setCEP,
	fetchAddressByCEP: addressActions.fetchAddressByCEP,
	setAddressPostalCodeRequest: addressActions.setAddressPostalCodeRequest,
	setAddressRequest: addressActions.setAddressRequest,
	setNoAddressesList: addressActions.setNoAddressesList,
	setSelectedProduct: menuActions.setSelectedProduct,
	setUserHistory,
	setNotification,
	setModality,
	addProduct,
	removeProduct,
	setOpenCart
})

export const NewAddress = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'addresses' })
)(NewAddressPage))
