import React from 'react'
import PropTypes from 'prop-types'
import {
  connect
} from 'react-redux'

import history from '../../../history'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'

import { ReactComponent as IconInfo } from '../../assets/icons/icon_info.svg'
import { ReactComponent as IconMotorcycle } from '../../assets/icons/icon_motorcycle.svg'
import { ReactComponent as IconPickup } from '../../assets/icons/icon_pickup.svg'
import { ReactComponent as IconCurrency } from '../../assets/icons/icon_currency.svg'
import { ReactComponent as IconClock } from '../../assets/icons/icon_clock.svg'
import { ReactComponent as IconStoreAgendamento } from '../../assets/icons/icon_agendamento.svg'
import { ReactComponent as IconIOS } from '../../assets/icon_ios.svg'
import { ReactComponent as IconAndroid } from '../../assets/icon_android.svg'

import {
  useDeviceLayout
} from '../../hooks'

import {
  DetailedStoreHeaderContainer,
  HeaderBanner,
  HeaderStore,
  StoreIcon,
  StoreDetails,
  StoreName,
  StoreStatus,
  StoreCosts,
  StoreCost,
  CostIconContainer,
  HeaderBannerMobile,
  AppDownload,
  Div,
  ButtonApp
} from './styles'
import { TITLE } from '../../../config'
import Logo from './../../assets/LogoVQPStore.png'

const getStatusProps = (args) => {
  const allStatus = {
    1: { name: 'Aberto', textClassName: 'store-status--open' },
    2: { name: 'Fechado', textClassName: 'store-status--close' },
    3: { name: 'Indisponível', textClassName: 'store-status--unavailable' },
    4: { name: 'Sem comunicação', textClassName: 'store-status--no-communication' },
    5: { name: 'Fechado Temporariamente', textClassName: 'store-status--temporarily-closed' },
    6: { name: 'Fechado Temporariamente', textClassName: 'store-status--temporarily-closed' }
  }

  return allStatus[args] || allStatus['2']
}

export const Context = React.createContext({})

export function HeaderComponent(props) {
  const {
    storeName,
    image,
    imageMobile,
    deliveryFee,
    deliveryMinimumValue,
    pickupFee,
    pickupMinimumValue,
    deliveryTime,
    modalityId,
    storeImg,
    scheduling,
    URLParameters,
    storeFees,
    storeStatus,
    isMenu,
    setAppDownload
  } = props

  const minimumValue = (modalityId === 2 ? pickupMinimumValue : deliveryMinimumValue) || 0

  const statusProps = getStatusProps(storeStatus)

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  return (
    <DetailedStoreHeaderContainer>
      {!isMobile ? <HeaderBanner>
        <img src={image} alt="banner" />
      </HeaderBanner> : <HeaderBannerMobile>
        <img src={imageMobile} alt="banner" />
      </HeaderBannerMobile>}
      <HeaderStore>
        <StoreIcon>
          <img src={storeImg ? storeImg : Logo} alt="logo" />
        </StoreIcon>
        <StoreDetails>
          <StoreName>
            <h1>{storeName ? storeName : TITLE}</h1>
            <IconInfo onClick={() => history.push(isMenu ? `/store-info${URLParameters}` : `/${URLParameters}`)} />
          </StoreName>
          <StoreStatus className={statusProps.textClassName}>
            {statusProps.name}
          </StoreStatus>
          <Div className="app">
            <StoreCosts>
              <StoreCost className='fee'>
                <CostIconContainer>
                  {modalityId === 4 ? <IconMotorcycle /> : <IconPickup />}
                </CostIconContainer>
                <h3>{modalityId === 2 ? 'taxa de retirada' : 'taxa de entrega'}</h3>

                <p>{storeFees ? (modalityId === 2 ? (
                  (pickupFee && `R$ ${pickupFee.toFixed(2).replace('.', ',')}`) || 'Grátis'
                ) : (
                  (deliveryFee && `R$ ${deliveryFee.toFixed(2).replace('.', ',')}`) || 'Grátis'
                )) : '…'}</p>
              </StoreCost>
              <StoreCost className='minimum-value'>
                <CostIconContainer>
                  <IconCurrency />
                </CostIconContainer>
                <h3>valor minimo</h3>
                <p>{minimumValue ? `R$ ${minimumValue.toFixed(2).replace('.', ',')}` : '...'}</p>
              </StoreCost>
              <StoreCost className='time'>
                <CostIconContainer>
                  {!scheduling ? <IconClock /> : <IconStoreAgendamento />}
                </CostIconContainer>
                <h3>{scheduling ? 'entrega agendada' : 'tempo de preparo'}</h3>
                {!scheduling ? <p>{deliveryTime ? deliveryTime : '...'}</p> : ''}
              </StoreCost>
            </StoreCosts>
            <AppDownload>
              <ButtonApp onClick={() => setAppDownload(true)}>
                <span>
                  Baixe nosso app:
                </span>
                <span>
                  <IconIOS />
                  <IconAndroid />
                </span>
              </ButtonApp>
            </AppDownload>
          </Div>
        </StoreDetails>
      </HeaderStore>
    </DetailedStoreHeaderContainer>
  )
}

HeaderComponent.propTypes = {
  deliveryFee: PropTypes.number,
  pickupFee: PropTypes.number,
  deliveryMinimumValue: PropTypes.number,
  deliveryTime: PropTypes.string,
  modalityId: PropTypes.number,
  pickupMinimumValue: PropTypes.number,
  storeName: PropTypes.string,
  image: PropTypes.string,
  imageMobile: PropTypes.string,
  status: PropTypes.number,
  handleModality: PropTypes.func,
  storeImg: PropTypes.string,
  accessToken: PropTypes.string,
  storeDelivery: PropTypes.bool,
  storePickup: PropTypes.bool,
  opened: PropTypes.bool,
  scheduling: PropTypes.bool,
  URLParameters: PropTypes.string,
  storeFees: PropTypes.shape({}),
  setVisibleNavigation: PropTypes.func,
  storeStatus: PropTypes.number,
  isMenu: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    deliveryFee: state.store.store && state.store.store.deliveryFee,
    deliveryMinimumValue: state.store.store && state.store.store.deliveryMinimumValue,
    deliveryTime: state.store.store && state.store.store.deliveryTime,
    modalityId: state.main.modality && state.main.modality.id,
    pickupFee: state.store.store && state.store.store.pickupFee,
    pickupMinimumValue: state.store.store && state.store.store.pickupMinimumValue,
    scheduling: state.store.store && state.store.store.scheduling,
    storeDelivery: (state.store.store && state.store.store.delivery) || null,
    storeFees: state.store.store && state.store.store.fees,
    storeImg: (state.store.store && state.store.store.logo) || '',
    storeName: state.store.store && state.store.store.name,
    storeStatus: state.store.store && state.store.store.status,
    storePickup: (state.store.store && state.store.store.pickup) || null,
    URLParameters: (state.main.URLParameters) || '',
    accessToken: (state.user.accessToken) || ''
  }
}

const GetConnection = connect(mapStateToProps, {})

export const Header = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'store' })
)(HeaderComponent))
