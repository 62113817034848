import React from 'react'
import PropTypes from 'prop-types'
import { Image, Shimmer } from 'react-shimmer'

import {
  Wrapper,
  Background,
  H2,
  StoreInfoWrapper,
  Span,
  ImageLogo,
  NameWrapper,
  Distance
} from './styles'

const StoreInfo = ({
  address
}) => {
  return <StoreInfoWrapper>
    <Span className='distance'>{`${address}`}</Span>
  </StoreInfoWrapper>
}

export function StoreAlternative(props) {
  const {
    handles,
    data
  } = props

  const {
    id,
    coupon = {},
    name,
    distance,
    category,
    address,
    delivery,
    logo,
    highlight,
    pickup
  } = data

  const { handleStore } = handles

  return <Wrapper onClick={() => {
    handleStore({
      id,
      coupon,
      modality: delivery ? 4 : 2,
      name,
      modalities: {
        delivery,
        pickup
      }
    })
  }}>
    {highlight && logo ? <Background>
      <Image className='banner' src={highlight} fallback={<Shimmer width={800} height={235} />} />
      <ImageLogo className='logo' src={logo} />
    </Background> : null}

    <NameWrapper>
      <H2>{name}</H2>
      <Distance>{`${distance} km`}</Distance>
    </NameWrapper>

    <StoreInfo address={address} category={category} />
  </Wrapper>
}

StoreAlternative.propTypes = {
  handles: PropTypes.shape({
    handleStore: PropTypes.func
  }),
  selectedStoreId: PropTypes.number,
  data: PropTypes.shape({
    id: PropTypes.number,
    coupon: PropTypes.shape({}),
    name: PropTypes.string,
    logo: PropTypes.string,
    distance: PropTypes.string,
    selectedStoreId: PropTypes.number,
    category: PropTypes.string,
    pickup: PropTypes.bool,
    delivery: PropTypes.bool,
    store: PropTypes.shape({
      id: PropTypes.number
    }),
  })
}
